import React from 'react';
import styles from './requestItem.module.scss';
import { ReactComponent as SupportSVG } from 'assets/images/sidebar/support.svg';

interface IRequestItemProps {
	checkx?: '1' | '3';
	title?: string;
	date?: string;
	onClick?: () => void;
}
export const RequestItem = ({
	title = 'ООО “Наименование Юрлица”',
	date = '21 нояб. 2022, 15:43',
	checkx = '1',
	onClick = () => {},
}: IRequestItemProps) => {
	return (
		<div className={styles.requestItem} onClick={checkx !== '3' ? onClick : () => {}}>
			<div className={styles.requestItem__info}>
				<SupportSVG />

				<span className={styles.requestItem__textBlock}>
					<span className={styles.requestItem__text}>Обращение от </span>
					<span className={styles.requestItem__title}>{title}</span>
				</span>
			</div>

			<div className={styles.requestItem__date}>
				<span>{date}</span>

				{checkx === '3' && (
					<span className={styles.requestItem__checkx}>Данные устарели. Необходимо обновить запрос</span>
				)}
			</div>
		</div>
	);
};
