import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getJWT } from 'services/api/getJWT';
import { IGetJWTPayload } from 'services/api/getJWT/interfaces';
import { IState, TLogOutPayload } from './interfaces';
import { cleanToken } from '../api/cleanToken';
import { AppDispatch } from './index';
import { updateSupportRequest } from '../api/updateSupportRequest';
import { fetchContractorInfo } from './user';
import { setContractor } from './user';
import { TCleanTokenPayload } from 'services/store/interfaces';

const initialState: IState = {
	value: null,
	error: '',
	loading: false,
};

export const fetchJWT = createAsyncThunk('jwt/fetchJWT', async (data: IGetJWTPayload) => {
	return await getJWT(data);
});

export const fetchLogOut = (data: TLogOutPayload) => async (dispatch: AppDispatch) => {
	try {
		await updateSupportRequest({ ContractorId: data.ContractorId, IsLogOut: data.IsLogOut });
		const res = await cleanToken({ IsDelete: data.IsDelete });

		if (res[0].CountDeleted) {
			localStorage.removeItem('token');
			localStorage.removeItem('userId');
			localStorage.removeItem('userName');
			localStorage.removeItem('userIsAllowedDemandPlan');
			dispatch(setToken(null));

			location.href = '/login';
		}
	} catch (e) {
		console.log('error cleanToken or update support request ==== ', e);
	}
};
export const fetchAuth = (payload: IGetJWTPayload) => async (dispatch: AppDispatch) => {
	try {
		const res = await dispatch(fetchJWT(payload));
		const data = res.payload[0];

		if (data.JWT) {
			const contractorResponse = await dispatch(fetchContractorInfo(data.JWT));
			const contractorData = contractorResponse.payload[0];

			if (contractorData.ContractorId) {
				localStorage.setItem('token', data.JWT);

				dispatch(setContractor(contractorData));
				dispatch(setToken(data.JWT));
				dispatch(setLoading(false));
			}

			if (contractorData.Result === '99') dispatch(setErrorAuth('home.auth'));
		}

		if (data.ErrorDescription) dispatch(setErrorAuth('home.error'));
	} catch (e) {
		dispatch(setError('home.error'));
	}
};

export const fetchCleanToken = createAsyncThunk('jwt/fetchCleanToken', async (data: TCleanTokenPayload) => {
	return await cleanToken(data);
});

const jwt = createSlice({
	name: 'jwt',
	initialState,
	reducers: {
		setToken: (state, action) => {
			if (action.payload) state.value = action.payload;
		},
		setSuperToken: (state, action) => {
			state.value = action.payload;
			localStorage.setItem('token', action.payload);
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setErrorAuth: (state, action) => {
			state.error = action.payload;
			state.value = null;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchJWT.pending, (state) => {
			state.loading = true;
			state.error = '';
		});
		builder.addCase(fetchJWT.fulfilled, () => {});
		builder.addCase(fetchJWT.rejected, () => {});
		builder.addCase(fetchCleanToken.pending, () => {});
		builder.addCase(fetchCleanToken.fulfilled, (state, action) => {
			const res = action.payload[0];

			if (res.CountDeleted) {
				localStorage.removeItem('token');
				localStorage.removeItem('userId');
				localStorage.removeItem('contractor');
				localStorage.removeItem('userName');
				localStorage.removeItem('activeSession');
				localStorage.removeItem('userIsAllowedDemandPlan');
				state.value = null;
				location.href = '/login';
			}
		});
		builder.addCase(fetchCleanToken.rejected, () => {});
	},
});

export const { setErrorAuth, setLoading, setSuperToken, setError, setToken } = jwt.actions;
export default jwt.reducer;
