import React from 'react';
import styles from './policy.module.scss';
import { useTranslation } from 'react-i18next';

export const Policy = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.policy}>
			<div className={styles.policy_header}>{t('rightMenu.howPersonal')}</div>

			<div className={styles.policy_content}>
				<p className={styles.policy_center}>
					<b>1. ОБЩИЕ ПОЛОЖЕНИЯ</b>
				</p>
				<p>
					1.1. Настоящая Политика в отношении обработки персональных данных ООО &laquo;Евроторг&raquo; (далее &ndash;
					Политика) разъясняет субъектам персональных данных каким образом и для каких целей их персональные данные
					собираются, используются или иным образом обрабатываются, а также определяет имеющиеся в связи с этим у
					субъектов персональных данных права и механизм их реализации.&nbsp;&nbsp;
				</p>
				<p>
					1.2. Политика разработана с учетом требований Конституции Республики Беларусь, Закона Республики Беларусь от
					07.05.2021 № 99-З &laquo;О защите персональных данных&raquo; (далее &ndash; Закон), иных нормативных правовых
					актов Республики Беларусь о персональных данных в целях защиты персональных данных, прав и свобод физических
					лиц при обработке их персональных данных.
				</p>
				<p>
					1.3. Положения Политики служат основой для разработки локальных правовых актов, регламентирующих у ООО
					&laquo;Евроторг&raquo; (далее &ndash; Оператор) вопросы обработки персональных данных.
				</p>
				<p>
					1.4. Политика не применяется к обработке персональных данных: работников в процессе трудовой деятельности и
					при осуществлении административных процедур (в отношении работников и бывших работников), пользователей
					интернет-сайтов Оператора (в части cookie-файлов), при осуществлении Оператором видеонаблюдения, а также в
					отношении совместной обработки сооператорами &ndash; ЗАО &laquo;Интернет-магазин Евроопт&raquo; и ООО
					&laquo;Евроторг&raquo; персональных данных, которые получены посредством интернет-сайтов:{' '}
					<a href="https://yamigom-store.by/">https://yamigom-store.by</a>,{' '}
					<a href="https://et.yamigom-store.by/">https://et.yamigom-store.by</a> и мобильного приложения &laquo;ЯМИГОМ
					&ndash; доставка продуктов&raquo;.
				</p>
				<p>
					1.5. Политика является общедоступной и размещается в глобальной компьютерной сети Интернет на следующих сайтах
					Оператора: <a href="https://ir.eurotorg.by/ru/">https://ir.eurotorg.by/ru</a>,{' '}
					<a href="https://invest.evroopt.by/">https://invest.evroopt.by</a>,{' '}
					<a href="https://e-account.by/">https://e-account.by</a>, <a href="https://evroopt.by/">https://evroopt.by</a>
					, <a href="https://eplus.by/">https://eplus.by</a>,{' '}
					<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,{' '}
					<a href="https://flowers.evroopt.by/">https://flowers.evroopt.by</a>,{' '}
					<a href="https://fermer.evroopt.by/">https://fermer.evroopt.by</a>,{' '}
					<a href="https://hitdiscount.by/">https://hitdiscount.by</a>,{' '}
					<a href="https://groshyk.by/">https://groshyk.by</a>,{' '}
					<a href="https://ecity.evroopt.by/">https://ecity.evroopt.by</a>,{' '}
					<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,{' '}
					<a href="https://hmelnov.by/">https://hmelnov.by</a>,{' '}
					<a href="https://sertifikat.evroopt.by">https://sertifikat.evroopt.by</a>,{' '}
					<a href="https://contractor-evroopt.by/">https://contractor-evroopt.by</a>,{' '}
					<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,{' '}
					<a href="https://e-rabota.by/">https://e-rabota.by</a>,{' '}
					<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a> (далее, совместно и по отдельности
					&ndash; Сайт) и <a href="https://et.yamigom-store.by/">https://et.yamigom-store.by</a>, а также в мобильных
					приложениях &laquo;Еплюс&raquo; и &laquo;ЯМИГОМ &ndash; доставка продуктов&raquo;.
				</p>
				<p>
					1.6. До предоставления согласия на обработку персональных данных субъекту персональных данных рекомендуется
					ознакомиться с настоящей Политикой.
				</p>
				<p>1.7. Наименование, местонахождение и иные контактные данные Оператора:</p>
				<p>1.7.1. Наименование Оператора: общество с ограниченной ответственностью &laquo;Евроторг&raquo;.</p>
				<p>1.7.2. Местонахождение Оператора:</p>
				<p>юридический адрес: 220099, г. Минск, ул. Казинца, д. 52а, ком. 22;</p>
				<p>почтовый адрес: 220019, г. Минск, ул. Монтажников, д. 2.</p>
				<p>1.7.3. Иные контактные данные Оператора:</p>
				<p>
					адрес в сети Интернет: <a href="https://ir.eurotorg.by/ru/">https://ir.eurotorg.by/ru</a>,{' '}
					<a href="https://evroopt.by/">https://evroopt.by</a>;
				</p>
				<p>
					e-mail: info@eurotorg.by; <a href="mailto:obraschenie_01@eurotorg.by">obraschenie_01@eurotorg.by</a>;
				</p>
				<p>телефон: +375 44 7888880, +375 17 2890000; факс +375 17 2798000.</p>
				<p>1.8. Политика действует в отношении персональных данных, обрабатываемых Оператором, которые получены:</p>
				<p>
					- посредством следующих интернет-сайтов: <a href="https://invest.evroopt.by/">https://invest.evroopt.by</a>,{' '}
					<a href="https://e-account.by">https://e-account.by</a>, <a href="https://evroopt.by/">https://evroopt.by</a>,{' '}
					<a href="https://eplus.by/">https://eplus.by</a>,{' '}
					<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,{' '}
					<a href="https://fermer.evroopt.by/">https://fermer.evroopt.by</a>,{' '}
					<a href="https://hitdiscount.by/">https://hitdiscount.by</a>,{' '}
					<a href="https://groshyk.by/">https://groshyk.by</a>,{' '}
					<a href="https://ecity.evroopt.by/">https://ecity.evroopt.by</a>,{' '}
					<a href="https://ir.eurotorg.by/ru">https://ir.eurotorg.by/ru</a>,{' '}
					<a href="https://sertifikat.evroopt.by">https://sertifikat.evroopt.by</a>,{' '}
					<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,{' '}
					<a href="https://e-rabota.by/">https://e-rabota.by</a>,{' '}
					<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a> (включая личный кабинет и формы
					обратной связи на Сайте);
				</p>
				<p>
					- посредством мобильного приложения &laquo;Еплюс&raquo; (далее &ndash; мобильное приложение), размещенного в
					сети Интернет по следующим адресам:
				</p>
				<ul className={styles.policy_list}>
					<li>
						App Store -{' '}
						<a href="https://apps.apple.com/by/app/%D0%B5%D0%BF%D0%BB%D1%8E%D1%81/id1535603669">
							https://apps.apple.com/by/app/еплюс/id1535603669
						</a>
						;
					</li>
					<li>
						Google Play -{' '}
						<a href="https://play.google.com/store/apps/details?id=by.besmart.euroopt">
							https://play.google.com/store/apps/details?id=by.besmart.euroopt
						</a>
						;
					</li>
					<li>
						AppGallery -{' '}
						<a href="https://appgallery.huawei.com/app/C104839767">https://appgallery.huawei.com/app/C104839767</a>.
					</li>
				</ul>
				<p>
					- посредством электронной почты, онлайн-рекрутера &laquo;Евроопт&raquo; в мессенджере Telegram, сайтов для
					поиска работы (например, rabota.by), телефонного звонка в контактный центр (на горячую линию) Оператора (в
					ходе телефонного разговора);
				</p>
				<p>- посредством проведения аудио- и (или) видеозаписи;</p>
				<p>
					- путем заполнения (направления) субъектом персональных данных письменных анкет (резюме) соискателей
					(кандидатов) на трудоустройство;
				</p>
				<p>
					- путем заполнения (направления) субъектом персональных данных письменных заявлений на получение бонусной
					карты &laquo;Еплюс. Социальная&raquo; (назначение бонусной карте &laquo;Еплюс&raquo; типа &laquo;Еплюс.
					Социальная&raquo;);
				</p>
				<p>
					- от субъектов персональных данных, с которыми Оператором заключены либо будут заключены (планируются к
					заключению) гражданско-правовые сделки (договоры);
				</p>
				<p>
					- от субъектов персональных данных, предоставивших Оператору персональные данные путем направления письменных
					(в том числе внесенных в книгу замечаний и предложений) и электронных обращений, а также в ходе личного
					приема;
				</p>
				<p>- от субъектов персональных данных &ndash; аффилированных лиц Оператора;</p>
				<p>- от субъектов персональных данных &ndash; посетителей объектов Оператора, находящихся под охраной.</p>
				<p>
					1.9. Лицом, ответственным у Оператора за осуществление внутреннего контроля за обработкой персональных данных,
					к которому можно обратиться за содействием в реализации прав субъекта персональных данных, является специалист
					по внутреннему контролю за обработкой персональных данных (почтовый адрес: 220019, г.&nbsp;Минск, ул.
					Монтажников, д. 2, e-mail: <a href="mailto:dpo@eurotorg.by">dpo@eurotorg.by</a>).
				</p>
				<p>1.10. Настоящая Политика действует с момента ее утверждения.</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>2. ПРИМЕНЯЕМЫЕ ТЕРМИНЫ И ИХ ОПРЕДЕЛЕНИЯ</b>
				</p>
				<p>&nbsp;</p>
				<p>2.1. В тексте Политики используются следующие термины и их определения:</p>
				<p>
					2.1.1. <i>Персональные данные</i> &ndash; любая информация, относящаяся к идентифицированному физическому лицу
					или физическому лицу, которое может быть идентифицировано;
				</p>
				<p>
					2.1.2. <i>Оператор</i> &ndash; государственный орган, юридическое лицо Республики Беларусь, иная организация,
					физическое лицо, в том числе индивидуальный предприниматель (далее, если не определено иное, &ndash;
					физическое лицо), самостоятельно или совместно с иными указанными лицами организующие и (или) осуществляющие
					обработку персональных данных;
				</p>
				<p>
					2.1.3.<i> Сооператоры </i>&ndash; это&nbsp;операторы, которые совместно организуют и (или) осуществляют
					обработку персональных данных;
				</p>
				<p>
					2.1.4. <i>Субъект персональных данных</i> &ndash; физическое лицо, в отношении которого осуществляется
					обработка персональных данных;
				</p>
				<p>
					2.1.5. <i>Уполномоченное лицо</i> &ndash; государственный орган, юридическое лицо Республики Беларусь, иная
					организация, физическое лицо, которые в соответствии с актом законодательства, решением государственного
					органа, являющегося оператором, либо на основании договора с оператором осуществляют обработку персональных
					данных от имени оператора или в его интересах;
				</p>
				<p>
					2.1.6. <i>Физическое лицо, которое может быть идентифицировано,</i> &ndash; физическое лицо, которое может
					быть прямо или косвенно определено, в частности через фамилию, собственное имя, отчество, дату рождения,
					идентификационный номер либо через один или несколько признаков, характерных для его физической,
					психологической, умственной, экономической, культурной или социальной идентичности;
				</p>
				<p>
					2.1.7. <i>Обработка персональных данных</i> &ndash; любое действие или совокупность действий, совершаемые с
					персональными данными, включая сбор, систематизацию, хранение, изменение, использование, обезличивание,
					блокирование, распространение, предоставление, удаление персональных данных;
				</p>
				<p>
					2.1.8. <i>Предоставление персональных данных</i> &ndash; действия, направленные на ознакомление с
					персональными данными определенных лица или круга лиц;
				</p>
				<p>
					2.1.9. <i>Распространение персональных данных</i> &ndash; действия, направленные на ознакомление с
					персональными данными неопределенного круга лиц;
				</p>
				<p>
					2.1.10. <i>Удаление персональных данных</i> &ndash; действия, в результате которых становится невозможным
					восстановить персональные данные в информационных ресурсах (системах), содержащих персональные данные, и (или)
					в результате которых уничтожаются материальные носители персональных данных;
				</p>
				<p>
					2.1.11. <i>Блокирование персональных данных</i> &ndash; прекращение доступа к персональным данным без их
					удаления;
				</p>
				<p>
					2.1.12. <i>Обезличивание персональных данных</i> &ndash; действия, в результате которых становится невозможным
					без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту
					персональных данных;
				</p>
				<p>
					2.1.13. <i>Трансграничная передача персональных данных</i> &ndash; передача персональных данных на территорию
					иностранного государства.
				</p>
				<p>
					2.2. Иные используемые в тексте Политики термины употребляются в значениях, применяемых в действующем
					законодательстве Республики Беларусь.
				</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>3. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ </b>
				</p>
				<p>&nbsp;</p>
				<p>
					3.1. Обработка персональных данных Оператором осуществляется в соответствии с требованиями законодательства
					Республики Беларусь. Оператор{' '}
					<strong>с уважением относится к соблюдению прав субъектов персональных данных.</strong>
				</p>
				<p>
					3.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных, а также на иных
					правовых основаниях, предусмотренных Законом и иными законодательными актами Республики Беларусь.
				</p>
				<p>
					3.3. Оператор осуществляет обработку персональных данных как с использованием средств автоматизации (в том
					числе в информационных системах и ресурсах), так и без использования таких средств, если при этом
					обеспечиваются поиск персональных данных и (или) доступ к ним по определенным критериям (картотеки, списки,
					базы данных, журналы и другое).
				</p>
				<p>
					3.4. Оператор устанавливает цели обработки персональных данных, категории субъектов персональных данных, чьи
					персональные данные подвергаются обработке, перечень обрабатываемых персональных данных, правовые основания
					обработки персональных данных и сроки их хранения согласно <b>Приложению</b>, которое является неотъемлемой
					частью настоящей Политики.
				</p>
				<p>
					3.5.&nbsp; Обработка персональных данных осуществляется Оператором путем совершения любых действий (в том
					числе их совокупности), включая сбор, систематизацию, хранение, изменение, использование, обезличивание,
					блокирование, предоставление, удаление персональных данных, а также их распространение путем опубликования на
					Сайте и в аккаунтах Оператора в социальных сетях и мессенджерах в сети Интернет, в средствах массовой
					информации.
				</p>
				<p>При этом распространение персональных данных Оператором осуществляется только в следующих случаях:</p>
				<p>
					- в отношении перечня персональных данных (за исключением &laquo;контактного номера телефона&raquo;),
					категорий субъектов персональных данных, целей и правовых оснований их обработки, указанных в пунктах 10-13 и
					17 <b>Приложения</b> к настоящей Политике;
				</p>
				<p>
					- в отношении следующего перечня персональных данных: &laquo;фамилия&raquo;, &laquo;инициалы&raquo;,
					&laquo;игровой код&raquo;, &laquo;сведения о выигрыше&raquo;, категорий субъектов персональных данных, целей и
					правовых оснований их обработки, указанных в пункте 9 <b>Приложения</b> к настоящей Политике.
				</p>
				<p>
					3.6. Предоставляя свое согласие на обработку персональных данных Оператору субъекты персональных данных
					соглашаются на совершение Оператором в соответствии с настоящей Политикой следующих действий с персональными
					данными как с использованием средств автоматизации, так и без их использования: сбор, систематизацию,
					хранение, изменение, использование, обезличивание, блокирование, предоставление, в том числе уполномоченным
					лицам (включая трансграничную передачу уполномоченным лицам), удаление персональных данных.
				</p>
				<p>
					В случаях, если обработка персональных данных предполагает их распространение, то Оператор при получении
					согласия от субъекта персональных данных на такую обработку отдельно указывает об этом, а также указывает
					конкретные условия, при которых возможно такое действие.&nbsp;&nbsp;
				</p>
				<p>
					3.7. Отказ в предоставлении согласия по одной или нескольким целям обработки персональных данных, а также
					отзыв ранее предоставленного согласия делает невозможным обработку Оператором персональных данных по
					соответствующим целям, и, как следствие, влечет невозможность оказания субъекту персональных данных услуг,
					соответствующих целям такой обработки, оказание которых предполагает обладание Оператором определенными
					персональными данными субъекта.
				</p>
				<p>
					3.8. Оператор ограничивает обработку персональных данных достижением конкретных, заранее заявленных законных
					целей.
				</p>
				<p>
					3.9. Оператор осуществляет обработку только тех персональных данных, которые необходимы для выполнения
					заявленных целей, и не допускает их избыточной обработки.
				</p>
				<p>
					3.10. Обработка персональных данных носит прозрачный характер. Субъекту персональных данных в случаях,
					предусмотренных Законом, предоставляется соответствующая информация, касающаяся обработки его персональных
					данных.&nbsp;
				</p>
				<p>
					3.11. Оператор принимает меры по обеспечению достоверности обрабатываемых им персональных данных, при
					необходимости обновляет их. &nbsp;&nbsp;
				</p>
				<p>
					3.12. Обработка специальных персональных данных осуществляется с принятием комплекса мер, направленных на
					предупреждение рисков, которые могут возникнуть при обработке таких персональных данных для прав и свобод
					субъектов персональных данных.
				</p>
				<p>3.13. Оператор осуществляет обработку персональных данных с привлечением уполномоченных лиц.</p>
				<p>
					3.14. Наименование и местонахождение уполномоченных лиц, которым Оператор вправе поручить обработку
					персональных данных от имени Оператора или в его интересах:
				</p>
				<p>
					- ООО &laquo;Яскрава&raquo; (юридический адрес: 220073, г. Минск, ул. Ольшевского, д. 22, оф. 902), с целью
					обеспечения участия субъектов персональных данных, зарегистрированных в личном кабинете на Сайте или в
					мобильном приложении, в рекламных играх и мероприятиях Оператора и его партнеров;
				</p>
				<p>
					- ООО &laquo;Свежие идеи&raquo; (юридический адрес: 220090, г. Минск, ул. Олешева, д. 9, пом. 5), с целью
					обеспечения участия субъектов персональных данных, зарегистрированных в личном кабинете на Сайте или в
					мобильном приложении, в рекламных играх и мероприятиях Оператора и его партнеров;
				</p>
				<p>
					- ЗАО &laquo;Второй национальный телеканал&raquo; (юридический адрес: 220029, г. Минск, ул. Коммунистическая,
					д. 6, ком. 205), с целью выполнения работ по видеосъемке (записи) и трансляции (опубликованию) проводимых
					розыгрышей рекламной игры &laquo;Удача в придачу!&raquo;, а также созданию и трансляции (опубликованию)
					рекламных роликов о победителях данной рекламной игры;
				</p>
				<p>
					- ООО &laquo;СМС ТРАФИК-БЕЛ&raquo; (юридический адрес: 220073, г. Минск, ул. Скрыганова, д. 6, корп. 1, пом.
					9, ком. 389), с целью осуществления отправки информационных (рекламных, новостных и других) сообщений
					посредством SMS и Viber, в том числе связанных с регистрацией и использованием личного кабинета на Сайте и в
					мобильном приложении, верификацией номера телефона, проведением маркетинговых и (или) рекламных мероприятий
					(акций, конкурсов, игр и других мероприятий);
				</p>
				<p>
					- СООО &laquo;Мобильные ТелеСистемы&raquo; (юридический адрес: 220012, г. Минск, пр-т Независимости, д. 95,
					пом. 4), с целью осуществления отправки информационных (рекламных, новостных и других) сообщений посредством
					SMS и Viber, в том числе связанных с регистрацией и использованием личного кабинета на Сайте и в мобильном
					приложении, верификацией номера телефона, проведением маркетинговых и (или) рекламных мероприятий (акций,
					конкурсов, игр и других мероприятий);
				</p>
				<p>
					- УП &laquo;А1&raquo; (юридический адрес: 220030, г. Минск, ул. Интернациональная, д. 36, пом. 2), с целью
					осуществления отправки информационных (рекламных, новостных и других) сообщений посредством SMS и Viber, в том
					числе связанных с регистрацией и использованием личного кабинета на Сайте и в мобильном приложении,
					верификацией номера телефона, проведением маркетинговых и (или) рекламных мероприятий (акций, конкурсов, игр и
					других мероприятий);
				</p>
				<p>
					- ООО &laquo;Инфосинтез&raquo; (юридический адрес: 220069, г. Минск, пр-т Дзержинского, д. 3Б, оф. 122), с
					целью осуществления отправки посредством электронной почты (E-mail) информационных (рекламных, новостных и
					других) сообщений, а также ответов на обращения (вопросы, отзывы), поступившие в виде электронного сообщения
					через формы &laquo;Задать вопрос&raquo; и &laquo;Обратная связь&raquo; на Сайте;
				</p>
				<p>
					- ООО &laquo;СКИЛАЗ&raquo; (юридический адрес: 121205, Российская Федерация, г. Москва, вн.тер.г.
					муниципальный округ Можайский, территория Сколково Инновационного Центра, ул. Нобеля, д. 5, этаж 1, пом. III,
					рабочее место 17, фактический адрес: 125167, Российская Федерация, г. Москва, Ленинградский пр-т, д. 37, этаж
					13 (БЦ &laquo;Аэродом&raquo;)), с целью обеспечения автоматизации процесса подбора персонала с использованием
					системы Skillaz;
				</p>
				<p>
					- ООО &laquo;СМАРТ ПЭЙ КОМПАНИ&raquo; (юридический адрес: 220002, г. Минск, пр-т Машерова, д. 25, оф. 611), с
					целью обеспечения функционирования систем мобильного приложения &laquo;Еплюс&raquo; и встроенной в него
					системы платежей Smart Pay, а также осуществления отправки информационных (рекламных, новостных и других)
					сообщений посредством Push, в том числе связанных с регистрацией и использованием личного кабинета в мобильном
					приложении, обращением бонусной карты и бонусов, обеспечением функционирования систем мобильного приложения и
					встроенной в него системы платежей Smart Pay (включая аутентификацию пользователей мобильного приложения,
					инициирование и проведение пользователями платежей и банковских операций в мобильном приложении);
				</p>
				<p>
					- Зубарик Олег Владимирович, являющийся плательщиком налога на профессиональный доход (адрес регистрации:
					222827, Минская область, г. Марьина Горка), с целью оказания услуг по настройке программного обеспечения
					обработки вызовов и иной информации, поступающих на телефоны контактного центра Оператора, а также
					программного обеспечения работы телефона горячей линии рекламной игры &laquo;Удача в придачу!&raquo;;
				</p>
				<p>
					- ООО &laquo;Надежные программы&raquo; (юридический адрес: 220006, г. Минск, ул. Аранская, д. 8, блок 1, 4
					этаж), с целью оказания услуг защищенного облачного хостинга (включая хранение информационных систем
					(ресурсов), содержащих персональные данные, а также резервных копий таких информационных систем (ресурсов),
					обеспечение доступа к данным информационным системам (ресурсам)).
				</p>
				<p>
					3.15. Уполномоченные лица, осуществляющие обработку персональных данных на основании заключаемого с Оператором
					договора (соглашения) об обработке персональных данных (поручения Оператора), обязуются соблюдать принципы,
					правила обработки и защиты персональных данных, предусмотренные Законом. Для каждого уполномоченного лица в
					договоре (соглашении) об обработке персональных данных определяются: цели обработки, перечень действий с
					персональными данными, которые будут совершаться таким лицом, устанавливаются его обязанности по соблюдению
					конфиденциальности и обеспечению защиты персональных данных в процессе их обработки, указываются необходимые
					меры по обеспечению защиты персональных данных в соответствии с Законом.
				</p>
				<p>
					3.16. Если для обработки персональных данных по поручению Оператора необходимо получение согласия субъекта
					персональных данных, такое согласие получает Оператор.
				</p>
				<p>
					Ответственность перед субъектом персональных данных за действия уполномоченного лица, которому Оператор
					поручил обработку персональных данных, несет Оператор.
				</p>
				<p>
					3.17. Персональные данные могут быть переданы третьим лицам, в том числе государственным органам и
					организациям, только по основаниям и в порядке, установленным законодательством Республики Беларусь.
				</p>
				<p>
					3.18. Оператор может передавать персональные данные лицам, находящимся в иностранных государствах
					(трансграничная передача персональных данных), в которых обеспечивается надлежащий уровень защиты прав
					субъектов персональных данных.
				</p>
				<p>
					Перечень иностранных государств, на территории которых обеспечивается надлежащий уровень защиты прав субъектов
					персональных данных, установлен приказом Национального центра защиты персональных данных Республики Беларусь
					от 15.11.2021 № 14 &laquo;О трансграничной передаче персональных данных&raquo;.
				</p>
				<p>
					3.19. Оператор с использованием средств автоматизации осуществляет трансграничную передачу персональных данных
					на территорию Российской Федерации уполномоченному лицу &ndash; ООО &laquo;СКИЛАЗ&raquo;, обеспечивающему
					автоматизацию процесса подбора персонала с использованием системы Skillaz, для проведения их
					автоматизированной обработки (в отношении субъектов персональных данных и в соответствии с целями их обработки
					и правовыми основаниями для такой передачи (обработки), которые указаны в пунктах 28 и 29 <b>Приложения</b> к
					настоящей Политике).
				</p>
				<p>
					Российская Федерация входит в перечень иностранных государств, на территории которых обеспечивается надлежащий
					уровень защиты прав субъектов персональных данных (определен уполномоченный орган по защите прав субъектов
					персональных данных, приняты нормативные правовые акты в области персональных данных и их защиты).
				</p>
				<p>
					3.20. Оператор с целью осуществления посредством Viber и электронной почты отправки информационных (рекламных,
					новостных и других) сообщений, в том числе связанных с регистрацией и использованием личного кабинета на Сайте
					и в мобильном приложении, верификацией номера телефона, проведением маркетинговых и (или) рекламных
					мероприятий (акций, конкурсов, игр и других мероприятий), а также ответов на обращения (вопросы, отзывы),
					поступившие в виде электронного сообщения через формы &laquo;Задать вопрос&raquo; и &laquo;Обратная
					связь&raquo; на Сайте (согласно пунктам 6, 7, 9, 14 и 16 <b>Приложения</b> к настоящей Политики), осуществляет
					трансграничную передачу следующих персональных данных:
				</p>
				<p>
					номера телефона &ndash; оператору сервиса обмена электронными сообщениями Viber Media S.&agrave; r.l., в том
					числе в иностранные государства, на территории которых не обеспечивается надлежащий уровень защиты прав
					субъектов персональных данных, поскольку коммуникационные серверы Viber Media S.&agrave; r.l. расположены в
					этих государствах;
				</p>
				<p>
					адреса электронной почты &ndash; почтовым сервисам обмена электронными сообщениями, в случае, если
					предоставленный субъектом персональных данных адрес электронной почты принадлежит почтовому сервису,
					коммуникационные серверы которого расположены за пределами Республики Беларусь, в том числе в иностранные
					государства, на территории которых не обеспечивается надлежащий уровень защиты прав субъектов персональных
					данных, поскольку указанные серверы отдельных почтовых сервисов расположены в этих государствах.
				</p>
				<p>
					С учетом вышеизложенного, Оператор информирует субъектов персональных данных о существовании рисков,
					возникающих в связи с отсутствием надлежащего уровня защиты персональных данных в таких иностранных
					государствах, а именно:
				</p>
				<p>отсутствие специального законодательства, регулирующего обработку персональных данных;</p>
				<p>
					отсутствие независимого уполномоченного государственного органа по защите прав субъектов персональных данных;
				</p>
				<p>
					ограниченный круг прав субъектов персональных данных, что может способствовать совершению неправомерных
					действий в отношении предоставляемых субъектом персональных данных на территории указанных иностранных
					государств.
				</p>
				<p>
					С дополнительной информацией о порядке обработки персональных данных Viber Media S.&agrave; r.l. можно
					ознакомиться на официальном сайте компании, размещенном в сети Интернет по адресу:{' '}
					<a href="https://www.viber.com/ru/terms/viber-privacy-policy/">
						https://www.viber.com/ru/terms/viber-privacy-policy/
					</a>
					, а с дополнительной информацией о порядке обработки персональных данных почтовыми сервисами &ndash; на
					официальных сайтах компаний, владеющих такими сервисами.
				</p>
				<p>
					3.21. Хранение персональных данных Оператором осуществляется в форме, позволяющей идентифицировать субъекта
					персональных данных, не дольше, чем этого требуют заявленные цели обработки персональных данных.
				</p>
				<p>
					3.22. Срок хранения документов, образующихся в процессе деятельности Оператора, в которых содержатся
					персональные данные субъектов, установлен постановлением Министерства юстиции Республики Беларусь от
					24.05.2012 №&nbsp;140 &laquo;О перечне типовых документов&raquo;, а также иными актами законодательства.
				</p>
				<p>
					Если срок хранения персональных данных субъектов персональных данных не установлен законодательством, такой
					срок устанавливается Оператором самостоятельно исходя из целей обработки персональных данных и специфики его
					деятельности.
				</p>
				<p>
					3.23. В случаях утраты или несанкционированного разглашения (распространения) персональных данных Оператор
					информирует субъекта персональных данных о произошедшей утрате или несанкционированном разглашении
					(распространении) персональных данных.
				</p>
				<p>
					3.24. Оператор в соответствии с Законом принимает необходимые правовые, организационные и технические меры по
					обеспечению защиты персональных данных от несанкционированного или случайного доступа к ним, изменения,
					блокирования, копирования, распространения, предоставления, удаления персональных данных, а также от иных
					неправомерных действий в отношении персональных данных.
				</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>4. СПОСОБЫ ПОЛУЧЕНИЯ СОГЛАСИЯ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
				</p>
				<p>&nbsp;</p>
				<p>
					4.1. Согласие субъекта персональных данных представляет собой свободное, однозначное, информированное
					выражение его воли, посредством которого он разрешает обработку своих персональных данных.
				</p>
				<p>
					4.2. Согласие субъекта персональных данных может быть получено Оператором в письменной форме, в виде
					электронного документа или в иной электронной форме.
				</p>
				<p>В иной электронной форме согласие субъекта персональных данных Оператор получает посредством:</p>
				<p>
					- проставления субъектом персональных данных напротив каждой из целей обработки персональных данных
					соответствующей отметки: на Сайте и в мобильном приложении (в том числе при регистрации и использовании
					личного кабинета), на одностраничном сайте (лендинге) системы Skillaz, обеспечивающей автоматизацию процесса
					подбора персонала (по адресу: <a href="https://candidate.skillaz.ru/">https://candidate.skillaz.ru</a>), в
					онлайн-рекрутере &laquo;Евроопт&raquo; в ходе онлайн-собеседования (с использованием мессенджера Telegram);
				</p>
				<p>
					- выбора и нажатия субъектом персональных данных соответствующей цифры набора номера в телефоне (при обращении
					(звонке) на телефоны контактного центра (горячей линии) Оператора).
				</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>5. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ И МЕХАНИЗМ ИХ РЕАЛИЗАЦИИ</b>
				</p>
				<p>&nbsp;</p>
				<p>5.1. Субъекты персональных данных обладают следующими правами:</p>
				<p>&nbsp;</p>
				<div className={styles.policy_table}>
					<table>
						<tbody>
							<tr>
								<td>
									<p>№</p>
								</td>
								<td>
									<p>Право</p>
								</td>
								<td>
									<p>Содержание права</p>
								</td>
								<td>
									<p>Последствия его реализации</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>1</p>
								</td>
								<td>
									<p>Право на отзыв предоставленного ранее согласия на обработку персональных данных&nbsp;&nbsp;</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе в любое время без объяснения причин отозвать свое ранее
										предоставленное Оператору согласие на обработку персональных данных посредством подачи Оператору
										заявления в письменной форме, либо в виде электронного документа, либо в форме, посредством которой
										получено его согласие. Вместе с тем, право на отзыв согласия не может быть реализовано в случаях,
										когда обработка персональных данных осуществляется на иных правовых основаниях (например, при
										получении персональных данных на основании договора в целях совершения установленных им действий или
										когда законодательными актами прямо предусматривается обработка персональных данных без согласия
										субъекта)&nbsp;
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных в
										соответствии с его содержанием прекратит обработку персональных данных, осуществит их удаление и
										уведомит об этом субъекта персональных данных, за исключением случаев, когда Оператор вправе
										продолжить обработку персональных данных при наличии оснований, предусмотренных законодательными
										актами. При отсутствии технической возможности удаления персональных данных Оператор примет меры по
										недопущению дальнейшей обработки персональных данных, включая их блокирование, и уведомит об этом
										субъекта персональных данных в тот же срок.
									</p>
									<p>&nbsp;</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>2</p>
								</td>
								<td>
									<p>Право на получение информации, касающейся обработки персональных данных</p>
								</td>
								<td>
									<p>
										Субъект персональных данных имеет право на получение информации, касающейся обработки своих
										персональных данных, содержащей:
									</p>
									<p>
										- наименование (фамилию, собственное имя, отчество (если таковое имеется)) и место нахождения (адрес
										места жительства (места пребывания)) оператора;
									</p>
									<p>- подтверждение факта обработки персональных данных оператором (уполномоченным лицом);</p>
									<p>- его персональные данные и источник их получения;</p>
									<p>- правовые основания и цели обработки персональных данных;</p>
									<p>- срок, на который дано его согласие;</p>
									<p>
										- наименование и место нахождения уполномоченного лица, которое является государственным органом,
										юридическим лицом Республики Беларусь, иной организацией, если обработка персональных данных
										поручена такому лицу;
									</p>
									<p>- иную информацию, предусмотренную законодательством.</p>
								</td>
								<td>
									<p>
										Оператор в течение 5 рабочих дней после получения заявления субъекта персональных данных предоставит
										в доступной форме запрашиваемую информацию, либо уведомит о причинах отказа в ее предоставлении.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>3</p>
								</td>
								<td>
									<p>Право на внесение изменений в свои персональные данные</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе требовать от Оператора внести изменения в свои персональные
										данные в случае, если они являются неполными, устаревшими или неточными. В этих целях субъект
										персональных данных прилагает к заявлению соответствующие документы и (или) их заверенные в
										установленном порядке копии, подтверждающие необходимость внесения изменений.
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных вносит
										&nbsp;соответствующие изменения в его персональные данные и уведомляет &nbsp;об этом субъекта
										персональных данных, либо уведомляет о причинах отказа во внесении таких изменений.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>4</p>
								</td>
								<td>
									<p>Право на получение информации о предоставлении персональных данных третьим лицам</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе получать от Оператора информацию о предоставлении своих
										персональных данных третьим лицам один раз в календарный год бесплатно.
									</p>
									<p>&nbsp;</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных предоставит
										ему информацию о том, какие персональные данные этого субъекта и кому предоставлялись в течение
										года, предшествовавшего дате подачи заявления, либо уведомит о причинах отказа в ее предоставлении.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>5</p>
								</td>
								<td>
									<p>Право требовать прекращения обработки персональных данных и (или) их удаления</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе требовать от Оператора бесплатного прекращения обработки своих
										персональных данных, включая их удаление, при отсутствии оснований для обработки персональных
										данных, предусмотренных законодательством Республики Беларусь.
									</p>
								</td>
								<td>
									<p>
										Оператор в пятнадцатидневный срок после получения заявления субъекта персональных данных прекратит
										обработку персональных данных, а также осуществит их удаление (обеспечит прекращение обработки
										персональных данных, а также их удаление уполномоченным лицом) и уведомит об этом субъекта
										персональных данных, либо откажет субъекту персональных данных в удовлетворении требований о
										прекращении обработки его персональных данных и (или) их удалении при наличии оснований для
										обработки персональных данных, предусмотренных законодательством&nbsp; Республики Беларусь, и
										уведомит об этом субъекта персональных данных в тот же срок. При отсутствии технической возможности
										удаления персональных данных Оператор примет меры по недопущению дальнейшей обработки персональных
										данных, включая их блокирование, и также уведомит об этом в пятнадцатидневный срок.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>6</p>
								</td>
								<td>
									<p>
										Право на обжалование действий (бездействия) и решений Оператора, связанных с обработкой персональных
										данных&nbsp;
									</p>
								</td>
								<td>
									<p>
										Субъект персональных данных вправе обжаловать действия (бездействие) и решения Оператора, нарушающие
										его права при обработке персональных данных, в Национальный центр защиты персональных данных
										Республики Беларусь (220003, г.Минск, ул. Клары Цеткин, 24-3), в порядке, установленном
										законодательством об обращениях граждан и юридических лиц.
									</p>
								</td>
								<td>
									<p>
										Национальным центром защиты персональных данных Республики Беларусь жалоба рассматривается в
										порядке, установленном законодательством об обращениях граждан и юридических лиц.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					5.2. Для реализации прав, указанных в пункте 5.1 Политики, субъекту персональных данных необходимо направить
					Оператору соответствующее заявление одним из следующих способов:
				</p>
				<p>- в письменной форме по адресу: 220019, г. Минск, ул. Монтажников, д. 2;</p>
				<p>
					- в виде электронного документа, содержащего электронную цифровую подпись субъекта персональных данных, на
					адрес электронной почты: <a href="mailto:dpo@eurotorg.by">dpo@eurotorg.by</a>.
				</p>
				<p>
					&nbsp;5.3. Для реализации права на отзыв согласия субъекту персональных данных необходимо направить Оператору
					соответствующее заявление в порядке, установленном пунктом 5.2 Политики.
				</p>
				<p>
					В случае, если согласие субъектом персональных данных было предоставлено в иной электронной форме, право на
					отзыв согласия может быть также реализовано в форме, посредством которой получено такое согласие:&nbsp;
				</p>
				<p>
					- путем снятия проставленных субъектом отметок (отметки) о даче согласия согласно выбранным целям обработки
					персональных данных в соответствующих окнах (окне) в личном кабинете на Сайте или в мобильном приложении (для
					целей обработки, указанных в пунктах 9-15 <b>Приложения</b> к настоящей Политике);
				</p>
				<p>
					- путем авторизации (входа) в личном кабинете на сайте{' '}
					<a href="https://e-account.by/">https://e-account.by</a> или в мобильном приложении &laquo;Еплюс&raquo; и его
					последующего удаления посредством нажатия &laquo;Удалить личный кабинет&raquo; (для целей обработки, указанных
					в пунктах 1-3, 5-7 <b>Приложения</b> к настоящей Политике);
				</p>
				<p>
					- путем авторизации (входа) в личном кабинете на сайте{' '}
					<a href="https://ir.eurotorg.by/ru/">https://ir.eurotorg.by/ru</a> в разделе &laquo;Центр результатов&raquo; и
					его последующего удаления посредством нажатия &laquo;Удалить личный кабинет&raquo; (для цели обработки,
					указанной в пункте 40 <b>Приложения</b> к настоящей Политике);
				</p>
				<p>
					- посредством направления электронного сообщения, содержащего фамилию, собственное имя, отчество (если таковое
					имеется), дату рождения, адрес места жительства (места пребывания) и изложение сути требований субъекта
					персональных данных, на адрес электронной почты{' '}
					<a href="mailto:odpobraschenie_01@eurotorg.by">dpo@eurotorg.by</a> (для целей обработки, указанных в пунктах
					28 и 29 <b>Приложения</b> к настоящей Политике);
				</p>
				<p>
					- посредством направления электронного сообщения, содержащего собственное имя и номер телефона, а также
					фамилию и отчество (если таковое имеется), которые были указаны субъектом персональных данных для получения
					обратной связи, и изложение сути его требований, на адрес электронной почты{' '}
					<a href="mailto:dpo@eurotorg.by">dpo@eurotorg.by</a> (для цели обработки, указанной в пункте 16{' '}
					<b>Приложения</b> к настоящей Политике).
				</p>
				<p>
					5.4. Право на отзыв согласия не может быть реализовано, если обработка персональных данных осуществляется на
					основании договора либо иных правовых основаниях, предусмотренных Законом и иными законодательными актами.
				</p>
				<p>
					5.5. Заявление субъекта персональных данных о реализации одного или нескольких перечисленных выше прав должно
					содержать:
				</p>
				<p>
					фамилию, собственное имя, отчество (если таковое имеется) субъекта персональных данных, адрес его места
					жительства (места пребывания);
				</p>
				<p>дату рождения субъекта персональных данных;</p>
				<p>
					идентификационный номер субъекта персональных данных, при отсутствии такого номера &ndash; номер документа,
					удостоверяющего личность субъекта персональных данных, в случаях, если эта информация указывалась субъектом
					персональных данных при даче своего согласия оператору или обработка персональных данных осуществляется без
					согласия субъекта персональных данных;
				</p>
				<p>изложение сути требований субъекта персональных данных;</p>
				<p>
					личную подпись (для заявления в письменной форме) либо электронную цифровую подпись (для заявления в виде
					электронного документа) субъекта персональных данных.
				</p>
				<p>
					5.6. Оператор не рассматривает заявления субъектов персональных данных, направленные иными способами (по
					телефону, факсу и т.п.).
				</p>
				<p>
					5.7. Ответ на заявление Оператором направляется субъекту персональных данных в форме, соответствующей форме
					подачи заявления, если в самом заявлении не указано иное.
				</p>
				<p>
					5.8. За содействием в реализации своих прав субъект персональных данных может также обратиться к лицу,
					ответственному за осуществление внутреннего контроля за обработкой персональных данных у Оператора, направив
					сообщение на адрес электронной почты <a href="mailto:dpo@eurotorg.by">dpo@eurotorg.by</a>.
				</p>
				<p>
					5.9. Подразумевается, что субъект персональных данных предоставляет Оператору достоверную информацию о своих
					персональных данных.
				</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>6. СООПЕРАТОР</b>
				</p>
				<p>&nbsp;</p>
				<p>
					6.1. Оператор вправе передавать персональные данные субъекта персональных данных совместному оператору &ndash;
					юридическому лицу, совместно с Оператором осуществляющему обработку персональных данных на основании абзаца
					восьмого статьи 1 Закона (далее &ndash; Сооператор).
				</p>
				<p>
					6.2. Оператор осуществляет обработку персональных данных совместно с Сооператором &ndash; ЗАО
					&laquo;Интернет-магазин Евроопт&raquo; (юридический адрес: 220019, Минская область, Минский район, Щомыслицкий
					с/с, Западный промузел, ТЭЦ-4, каб. 229, почтовый адрес: 220136, г. Минск, ул. Брикета, д. 30, (6 этаж, блок
					&laquo;Администрация&raquo;), адрес для корреспонденции: а/я 19, 220019, г. Минск).
				</p>
				<p>
					6.3. Оператор совместно с Сооператором ЗАО &laquo;Интернет-магазин Евроопт&raquo; обрабатывает персональные
					данные, указанные в пункте 9 <b>Приложения</b> к настоящей Политике, в соответствии с названными там же целями
					обработки, категориями субъектов персональных данных, правовыми основаниями обработки и сроками хранения
					персональных данных.
				</p>
				<p>
					6.4. Сооператор осуществляет обработку персональных данных как с использованием средств автоматизации (в том
					числе в информационных системах и ресурсах), так и без использования таких средств, если при этом
					обеспечиваются поиск персональных данных и (или) доступ к ним по определенным критериям (картотеки, списки,
					базы данных, журналы и другое), путем совершения любых действий (в том числе их совокупности), включая сбор,
					систематизацию, хранение, изменение, использование, обезличивание, блокирование, предоставление и удаление
					персональных данных.
				</p>
				<p>
					6.5. Предоставляя свое согласие на обработку персональных данных Оператору субъекты персональных данных
					выражают его для достижения конкретной цели также и Сооператору.
				</p>
				<p>
					6.6. Срок хранения персональных данных Сооператором совпадает со сроком хранения персональных данных
					Оператором для тождественной цели совместной обработки персональных данных.
				</p>
				<p>
					6.7. Для реализации прав, связанных с обработкой персональных данных Сооператором, субъекту персональных
					данных необходимо обращаться к Оператору или Сооператору, которому субъектом предоставлялось свое согласие на
					обработку персональных данных, в порядке, определенном разделом 5 настоящей Политики.
				</p>
				<p>&nbsp;</p>
				<p className={styles.policy_center}>
					<b>7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b>
				</p>
				<p>&nbsp;</p>
				<p>
					7.1. Оператор вправе по своему усмотрению в одностороннем порядке изменить и (или) дополнить условия настоящей
					Политики без предварительного уведомления субъектов персональных данных посредством размещения на Сайте и в
					мобильном приложении Оператора новой редакции Политики.
				</p>
				<p>
					7.2. Новая редакция Политики вступает в силу с момента ее размещения на Сайте (в мобильном приложении)
					Оператора, если иное не предусмотрено новой Политикой.
				</p>
				<p>
					7.3. Вопросы обработки персональных данных, которые не отражены в настоящей Политике, регулируются действующим
					законодательством Республики Беларусь.&nbsp;
				</p>

				<p className={styles.policy_center}>
					<b>Приложение</b>
				</p>
				<p className={styles.policy_center}>
					<b>к Политике ООО &laquo;Евроторг&raquo; в отношении обработки персональны</b>
				</p>
				<p className={styles.policy_center}>
					<b>
						Перечень обрабатываемых персональных данных, цели их обработки, категории субъектов персональных данных, чьи
						данные подвергаются обработке, правовые основания обработки, сроки хранения персональных данных.
					</b>
				</p>
				<p>Оператор осуществляет обработку персональных данных в следующих случаях:</p>
			</div>

			<div className={styles.policy_table}>
				<table>
					<thead>
						<tr>
							<th>
								<p>№ п/п</p>
							</th>
							<th>
								<p>Цели&nbsp;обработки персональных данных</p>
							</th>
							<th>
								<p>Категории субъектов персональных данных, чьи данные подвергаются обработке</p>
							</th>
							<th>
								<p>Перечень обрабатываемых персональных данных</p>
							</th>
							<th>
								<p>Правовые основания обработки персональных данных</p>
							</th>
							<th>
								<p>Срок&nbsp;хранения персональных данных</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<p>1.</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности &laquo;Еплюс&raquo;, обращение бонусной карты и бонусов,
									верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном
									кабинете на сайте <a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении &laquo;Еплюс&raquo;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности &laquo;Еплюс&raquo;, IP-адрес устройства,
									метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>
									5 лет даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета &ndash; 5 лет с даты дачи
									согласия
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>2.</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности &laquo;Еплюс. Пенсионная&raquo;, обращение бонусной карты
									и бонусов, верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности &laquo;Еплюс. Пенсионная&raquo;, зарегистрировавшие бонусную карту в
									личном кабинете на сайте <a href="https://e-account.by/">https://e-account.by</a>, в том числе через
									сайты: <a href="https://evroopt.by/">https://evroopt.by</a>,{' '}
									<a href="https://eplus.by/">https://eplus.by</a>,{' '}
									<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении &laquo;Еплюс&raquo;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности &laquo;Еплюс. Пенсионная&raquo;, IP-адрес
									устройства, метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>
									5 лет с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета &ndash; 5 лет с даты дачи
									согласия
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>3.</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности &laquo;Еплюс. Добрый процент&raquo;, обращение бонусной
									карты и бонусов, верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности &laquo;Еплюс. Добрый процент&raquo;, зарегистрировавшие бонусную карту
									в личном кабинете на сайте, <a href="https://e-account.by/">https://e-account.by</a>, в том числе
									через сайты:&nbsp; <a href="https://evroopt.by/">https://evroopt.by</a>,{' '}
									<a href="https://eplus.by/">https://eplus.by</a>,{' '}
									<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении &laquo;Еплюс&raquo;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона, адрес электронной почты (необязательно), номер
									бонусной карты, сведения об участии в программе лояльности &laquo;Еплюс. Добрый процент&raquo;,
									IP-адрес устройства, метаданные браузера, UUID (универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>
									5 лет с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета &ndash; 5 лет с даты дачи
									согласия
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>4.</p>
							</td>
							<td>
								<p>
									Получение бонусной карты с типом &laquo;Еплюс. Социальная&raquo; (либо назначение бонусной карте
									&laquo;Еплюс&raquo;, при ее наличии, типа &laquo;Еплюс. Социальная&raquo;)
								</p>
							</td>
							<td>
								<p>Покупатели, принадлежащие к социально уязвимым категориям населения</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, номер бонусной карты, &nbsp;реквизиты документов, подтверждающих
									принадлежность к социально уязвимым категориям населения (номер и дата выдачи пенсионного
									удостоверения, серия, номер и дата выдачи удостоверения инвалида, серия, номер и дата выдачи
									удостоверения ребенка- инвалида, номер и дата выдачи удостоверения многодетной семьи, номер и дата
									выдачи справки о предоставлении государственной адресной социальной помощи в виде ежемесячного
									социального пособия, номер и дата выдачи доверенности или удостоверения на право представления
									интересов подопечного (для представителя инвалида), дата свидетельства о рождении ребенка или
									свидетельства об усыновлении (удочерении) (для законных представителей &ndash; родителей или
									усыновителей (удочерителей)), номер и дата выдачи удостоверения на право представления интересов
									подопечного (для законных представителей &ndash; опекунов, попечителей)), вид документа,
									удостоверяющего личность, который предъявляется при получении бонусной карты (паспорт или
									идентификационная карта гражданина Республики&nbsp; Беларусь, либо вид на жительство в Республике
									Беларусь)
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>5 лет с даты дачи согласия</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>5.</p>
							</td>
							<td>
								<p>
									Регистрация и участие в программе лояльности &laquo;Еплюс. Социальная&raquo;, обращение бонусной карты
									и бонусов, верификация номера телефона
								</p>
							</td>
							<td>
								<p>
									Участники программы лояльности &laquo;Еплюс. Социальная&raquo;, зарегистрировавшие бонусную карту в
									личном кабинете на сайте <a href="https://e-account.by/">https://e-account.by</a>, в том числе через
									сайты: <a href="https://evroopt.by/">https://evroopt.by</a>,{' '}
									<a href="https://eplus.by/">https://eplus.by</a>,{' '}
									<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении &laquo;Еплюс&raquo;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона,
									<br />
									адрес электронной почты (необязательно), номер бонусной карты, сведения об участии в программе
									лояльности &laquo;Еплюс. Социальная&raquo;, IP-адрес устройства, метаданные браузера, UUID
									(универсальный уникальный идентификатор)
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последнего использования бонусной карты или последней авторизации (входа) в личном
									кабинете, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>6.</p>
							</td>
							<td>
								<p>
									Создание (регистрация) и использование личного кабинета на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:&nbsp; &nbsp;
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>
								</p>
							</td>
							<td>
								<p>Пользователи сайта</p>
								<p>
									<a href="https://e-account.by/">https://e-account.by</a>,{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>зарегистрировавшие личный кабинет</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона,
									<br />
									адрес электронной почты (необязательно), номер бонусной карты (необязательно), сведения
									<br />
									об участии в программах
									<br />
									лояльности &laquo;Еплюс&raquo; (необязательно), IP-адрес устройства, метаданные браузера, UUID
									(универсальный уникальный идентификатор)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>7.</p>
							</td>
							<td>
								<p>
									Создание (регистрация) и использование личного кабинета в мобильном приложении &laquo;Еплюс&raquo;,
									обеспечение функционирования систем мобильного приложения &laquo;Еплюс&raquo; и встроенной в него
									системы платежей Smart Pay (включая аутентификацию пользователей, инициирование и проведение
									пользователями платежей и банковских операций в мобильном приложении)
								</p>
							</td>
							<td>
								<p>Пользователи мобильного приложения &laquo;Еплюс&raquo;, зарегистрировавшие личный кабинет</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, контактный номер телефона,
									<br />
									адрес электронной почты (необязательно), номер бонусной карты, сведения об участии в программах
									лояльности &laquo;Еплюс&raquo;, ManID (уникальный идентификатор пользователя),
									<br />
									IP-адрес устройства, метаданные браузера, UUID (универсальный уникальный идентификатор), сведения об
									операционной системе и ее версии на устройстве пользователя, марка и модель устройства
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>8.</p>
							</td>
							<td>
								<p>
									Блокировка бонусной карты &laquo;Еплюс&raquo;, перенос истории бонусной карты &laquo;Еплюс&raquo; со
									старой на новую бонусную карту, возврат бонусов на бонусную карту &laquo;Еплюс&raquo; при возврате
									товара
								</p>
							</td>
							<td>
								<p>Участники программ лояльности &laquo;Еплюс&raquo;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, номер бонусной карты, личная подпись
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных, указанных в документе, адресованном оператору и подписанном субъектом
									персональных данных, в соответствии
									<br />
									с содержанием такого документа (абзац 16 ст. 6&nbsp; Закона от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>1 год с даты обращения</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>9.</p>
							</td>
							<td>
								<p>
									Проведение маркетинговых и (или) рекламных мероприятий (акций, конкурсов, игр и других мероприятий)
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении &laquo;Еплюс&raquo;</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (инициалы), дата рождения (необязательно), пол (необязательно),
									количество членов семьи (необязательно), контактный номер телефона, дополнительный номер телефона
									(необязательно), адрес электронной почты (необязательно), адрес места жительства (страна, область,
									район, населенный пункт, улица,
									<br />
									дом, корпус (при наличии),
									<br />
									подъезд (при наличии), этаж
									<br />
									(при наличии), квартира (при наличии)), номер бонусной карты, сведения об участии в программах
									лояльности &laquo;Еплюс&raquo;, игровой код, сведения о выигрыше (конкретный перечень обрабатываемых
									персональных данных определяется правилами проведения соответствующих маркетинговых и (или) рекламных
									мероприятий)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>10.</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя в прямом эфире трансляции розыгрыша рекламной игры &laquo;Удача в
									придачу!&raquo; на телеканале &laquo;ОНТ&raquo;
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,<br />
									или в мобильном приложении &laquo;Еплюс&raquo;, ставшие победителями рекламной игры &laquo;Удача в
									придачу!&raquo;
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность), сведения о выигрыше, игровой код, фотоизображение,
									видеозапись и аудиозапись звонка победителю в прямом эфире трансляции розыгрыша рекламной игры
									&laquo;Удача в придачу!&raquo; на телеканале &laquo;ОНТ&raquo;, видеозапись участия победителя в
									прямом эфире трансляции розыгрыша рекламной игры &laquo;Удача в придачу!&raquo; на телеканале
									&laquo;ОНТ&raquo;
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>11.</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя рекламной игры &laquo;Удача в придачу!&raquo; в<br />
									съемке по месту
									<br />
									жительства видеоролика о рекламной игре
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,<br />
									или в мобильном приложении &laquo;Еплюс&raquo;, ставшие победителями рекламной игры &laquo;Удача в
									придачу!&raquo;
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность), сведения о выигрыше, игровой код, фотоизображение,
									видеозапись участия победителя рекламной игры &laquo;Удача в придачу!&raquo; в съемке по месту
									жительства видеоролика о рекламной игре
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>12.</p>
							</td>
							<td>
								<p>
									Участие в качестве победителя в трансляции (съемке) церемонии награждения победителей рекламной игры
									&laquo;Удача в придачу!&raquo;
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,<br />
									или в мобильном приложении &laquo;Еплюс&raquo;, ставшие победителями рекламной игры &laquo;Удача в
									придачу!&raquo;
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, населенный пункт (район, область) места
									жительства, род занятий (занимаемая должность), сведения о выигрыше, игровой код, фотоизображение,
									видеозапись участия победителя
									<br />в трансляции (съемке) церемонии награждения победителей рекламной игры &laquo;Удача в
									придачу!&raquo;
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>13.</p>
							</td>
							<td>
								<p>
									Использование персональных данных победителей рекламной игры &laquo;Удача в придачу!&raquo; и иных
									маркетинговых и (или) рекламных мероприятий (конкурсов, игр и других)
									<br />
									в рекламных материалах
									<br />
									ООО &laquo;Евроторг&raquo; &nbsp;(размещаемых, в том числе на интернет-сайтах и<br />
									в аккаунтах ООО &laquo;Евроторг&raquo; в социальных сетях и мессенджерах
									<br />в сети Интернет, в эфире радиостанции &laquo;Альфа Радио&raquo;, иных средствах массовой
									информации)
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,<br />
									или в мобильном приложении &laquo;Еплюс&raquo;, ставшие победителями рекламной игры &laquo;Удача в
									придачу!&raquo; или иных маркетинговых и (или) рекламных мероприятий (конкурсов, игр и других)
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, населенный пункт (район, область) места жительства, род занятий
									(занимаемая должность), сведения о выигрыше, игровой код, фотоизображение, видеозапись и аудиозапись
									звонка победителю в прямом эфире трансляции розыгрыша рекламной игры &laquo;Удача в придачу!&raquo; на
									телеканале &laquo;ОНТ&raquo;, видеозапись участия победителя в прямом эфире трансляции розыгрыша
									рекламной игры &laquo;Удача в придачу!&raquo; на телеканале &laquo;ОНТ&raquo;, видеозапись участия
									победителя рекламной игры &laquo;Удача в придачу!&raquo; в съемке по
									<br />
									месту жительства видеоролика о рекламной игре, видеозапись участия победителя в трансляции (съемке)
									церемонии награждения победителей рекламной игры &laquo;Удача в придачу!&raquo;&nbsp;
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>14.</p>
							</td>
							<td>
								<p>
									Направление информационной (рекламной, новостной и другой) рассылки посредством электронной почты и
									(или) SМS, Viber, Push
								</p>
							</td>
							<td>
								<p>
									Пользователи Сайта, пользователи мобильного приложения &laquo;Еплюс&raquo;, зарегистрированные в
									личном кабинете на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:
								</p>
								<p>
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (необязательно), контактный номер телефона
									<br />
									(для рассылки посредством SМS, Viber, Push), адрес электронной
									<br />
									почты (для рассылки посредством электронной почты), ManID (уникальный идентификатор пользователя) (для
									рассылки посредством Push), номер
									<br />
									бонусной карты (необязательно)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash;
									<br />5 лет с даты дачи согласия, либо до момента отказа (отписки) от получения информационной
									рассылки
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>15.</p>
							</td>
							<td>
								<p>
									Демонстрация более подходящей по содержанию (релевантной) рекламной информации (об акциях, скидках и
									предложениях ООО &laquo;Евроторг&raquo; и привлеченных им партнеров) в глобальной компьютерной сети
									Интернет на посещаемых интернет-ресурсах (сайтах, в социальных сетях) и в мобильных приложениях
								</p>
							</td>
							<td>
								<p>
									Пользователи Сайта, пользователи мобильного приложения &laquo;Еплюс&raquo;, зарегистрированные в
									личном кабинете на сайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:
									<br />
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>или в мобильном приложении</p>
							</td>
							<td>
								<p>
									Контактный номер телефона,
									<br />
									адрес электронной почты (необязательно), дата рождения (необязательно), пол (необязательно),
									количество членов семьи (необязательно), адрес места жительства (страна, область, район, населенный
									пункт, улица, дом) (необязательно)
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>
									5 лет с даты последней авторизации (входа) в личном кабинете или последнего использования бонусной
									карты, а в случае неиспользования бонусной карты и личного кабинета &ndash; 5 лет с даты дачи
									согласия, либо до момента отказа (отписки) от демонстрации релевантной рекламной информации
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>16.</p>
							</td>
							<td>
								<p>
									Оказание клиентской поддержки и рассмотрение поступивших в виде электронного сообщения через формы
									&laquo;Задать вопрос&raquo; и &laquo;Обратная
									<br />
									связь&raquo; на сайтах:
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>,
								</p>
								<p>
									<a href="https://evroopt.by/">https://evroopt.by</a>, &nbsp;
									<a href="https://eplus.by/">https://eplus.by</a>, &nbsp;
									<a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>, &nbsp;
									<a href="https://hitdiscount.by/">https://hitdiscount.by</a>, &nbsp;
									<a href="https://groshyk.by/">https://groshyk.by</a>,
								</p>
								<p>
									<a href="https://fermer.evroopt.by/">https://fermer.evroopt.by</a>,
								</p>
								<p>
									<a href="https://invest.evroopt.by/">https://invest.evroopt.by</a>
								</p>
								<p>
									обращений (вопросов, отзывов) по качеству обслуживания, товаров и услуг, а также другим вопросам,
									интересуемым клиентов, направление ответов на такие обращения
								</p>
							</td>
							<td>
								<p>
									Пользователи Сайта, пользователи мобильного приложения &laquo;Еплюс&raquo; (покупатели, участники
									программ лояльности &laquo;Еплюс&raquo;, представители юридических лиц и индивидуальные
									предприниматели, иные физические лица)
								</p>
							</td>
							<td>
								<p>
									Фамилия (необязательно), собственное имя, отчество (необязательно), номер телефона, адрес электронной
									почты (необязательно, заполняется только при желании получить ответ по электронной
									<br />
									почте), номер бонусной
									<br />
									карты (необязательно), иные персональные данные, указанные
									<br />в обращении
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>3 года с даты дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td>
								<p>
									Опубликование на Сайте ООО &laquo;Евроторг&raquo; в сети Интернет сведений о победителях рекламной
									игры (рекламных игр)
								</p>
							</td>
							<td>
								<p>
									Участники программ лояльности &laquo;Еплюс&raquo;, зарегистрировавшие бонусную карту в личном кабинете
									на cайте
									<br />
									<a href="https://e-account.by/">https://e-account.by</a>, в том числе через сайты:
									<br />
									<a href="https://evroopt.by/">https://evroopt.by</a>, <a href="https://eplus.by/">https://eplus.by</a>
									, <a href="https://igra.evroopt.by/">https://igra.evroopt.by</a>,
								</p>
								<p>
									<a href="https://game.evroopt.by/">https://game.evroopt.by</a>,
								</p>
								<p>
									<a href="https://igra.groshyk.by/">https://igra.groshyk.by</a>,
								</p>
								<p>
									<a href="https://bonsticks.evroopt.by/">https://bonsticks.evroopt.by</a>,
								</p>
								<p>
									или в мобильном приложении &laquo;Еплюс&raquo;, которые стали победителями рекламной игры (рекламных
									игр)
								</p>
							</td>
							<td>
								<p>Фамилия, инициалы, сведения о выигрыше, игровой код</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),&nbsp;
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021 № 99-З &laquo;О защите
									персональных данных&raquo;, пункт 11 Положения о проведении рекламных игр на территории Республики
									Беларусь, утвержденного Указом Президента Республики Беларусь от 30.01.2003 № 51 &laquo;О проведении
									рекламных игр в Республике Беларусь&raquo;)
								</p>
							</td>
							<td>
								<p>10 лет со дня окончания рекламной игры</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>18.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических лиц и индивидуальных предпринимателей на участие в партнерской
									программе &laquo;Еплюс&raquo;
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и индивидуальные предприниматели, подавшие через сайт{' '}
									<a href="https://eplus.by/">https://eplus.by</a> заявку на участие в партнерской программе
									&laquo;Еплюс&raquo;
								</p>
							</td>
							<td>
								<p>
									Фамилия (необязательно), собственное имя, отчество (необязательно), контактный номер телефона, адрес
									электронной почты (необязательно, заполняется только при желании получить ответ по электронной почте)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (абзац 8 ст. 6 Закона от 07.05.2021 №
									99-З &laquo;О защите персональных данных&raquo;, ст.ст. 7, 49 и пункт 5 ст. 186 Гражданского кодекса
									Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>19.</p>
							</td>
							<td>
								<p>Рассмотрение заявок юридических лиц (крестьянских и фермерских хозяйств) на сотрудничество</p>
							</td>
							<td>
								<p>
									Представители юридических лиц (крестьянских и фермерских хозяйств), подавшие через сайт{' '}
									<a href="https://fermer.evroopt.by/">https://fermer.evroopt.by</a> заявку на сотрудничество
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>Собственное имя и контактный номер телефона</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (абзац 8 ст. 6 Закона от 07.05.2021 №
									99-З &laquo;О защите персональных данных&raquo;, ст.ст. 7, 49 и пункт 5 ст. 186 Гражданского кодекса
									Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>20.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических лиц
									<br />и индивидуальных предпринимателей на сотрудничество в сфере внутриреспубликанских грузоперевозок
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и индивидуальные предприниматели, подавшие через сайт{' '}
									<a href="https://evroopt.by/">https://evroopt.by</a> заявку (карточку перевозчика) на сотрудничество в
									сфере внутриреспубликанских грузоперевозок
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, регистрационный знак автомобиля и
									полуприцепа/прицепа
								</p>
								<p>(для индивидуального предпринимателя)</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется
									<br />
									в процессе трудовой (служебной) деятельности субъекта персональных данных
									<br />
									в случаях, предусмотренных законодательством (для юридических лиц),
									<br />и на основании договора, заключаемого с субъектом персональных данных, в целях совершения
									действий, установленных этим договором (для индивидуальных предпринимателей)
								</p>
								<p>
									(абзацы 8 и 15 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;, ст.ст. 7, 49
									<br />и пункт 5 ст. 186 Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>21.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических и физических лиц, в том числе индивидуальных предпринимателей, на
									аренду вакантных площадей
									<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Физические лица (осуществляющие деятельность, направленную на получение прибыли,
									<br />
									но не имеющие статуса индивидуального предпринимателя, а также индивидуальные предприниматели),
									представители юридических лиц, подавшие через сайты:{' '}
									<a href="https://ecity.evroopt.by/">https://ecity.evroopt.by</a>, &nbsp;
								</p>
								<p>
									<a href="https://evroopt.by/">https://evroopt.by</a>
								</p>
								<p>
									или посредством электронной почты заявку на аренду вакантных площадей
									<br />
									ООО &laquo;Евроторг&raquo;
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, занимаемая должность, фактический и почтовый адрес (для
									физического лица), контактный номер телефона, адрес электронной почты, банковские реквизиты (для
									физического лица)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется
									<br />
									в процессе трудовой (служебной) деятельности субъекта персональных данных
									<br />
									в случаях, предусмотренных законодательством (для юридических лиц),
									<br />и на основании договора, заключаемого с субъектом персональных данных, в целях совершения
									действий, установленных этим договором (для физических лиц)
								</p>
								<p>
									(абзацы 8 и 15 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;, ст.ст. 7, 49
									<br />и пункт 5 ст. 186 Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>22.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок физических лиц &ndash; арендаторов
									<br />
									ООО &laquo;Евроторг&raquo; на проведение промо-мероприятий в торговых объектах ООО
									&laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Физические лица &ndash; арендаторы ООО Евроторг&raquo;&nbsp; (осуществляющие деятельность,
									направленную на получение прибыли, но
									<br />
									не имеющие статуса индивидуального предпринимателя, а<br />
									также индивидуальные предприниматели), подавшие через сайт{' '}
									<a href="https://ecity.evroopt.by/">https://ecity.evroopt.by</a> или посредством электронной почты
									заявку на
									<br />
									проведение промо-мероприятия в торговых объектах ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>Фамилия, собственное имя, отчество, адрес электронной почты&nbsp;</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется на основании
									<br />
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (абзац 15 ст. 6 Закона от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;, ст. 7 Гражданского кодекса Республики
									Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>23.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических и физических лиц, в том числе индивидуальных предпринимателей, на
									размещение рекламной информации в<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Физические лица (осуществляющие деятельность, направленную на получение прибыли, но
									<br />
									не имеющие статуса индивидуального предпринимателя, а также индивидуальные предприниматели),
									представители юридических лиц, подавшие через
									<br />
									сайт <a href="https://evroopt.by/">https://evroopt.by</a>
								</p>
								<p>
									или посредством электронной почты
									<br />
									заявку на размещение
									<br />
									рекламной информации в<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется
									<br />
									в процессе трудовой (служебной) деятельности субъекта персональных данных
									<br />
									в случаях, предусмотренных законодательством (для юридических лиц),
									<br />и на основании договора,
								</p>
								<p>
									заключаемого с субъектом персональных данных, в целях совершения действий, установленных этим
									договором (для физических лиц)
								</p>
								<p>
									(абзацы 8 и 15 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;, ст.ст. 7, 49
									<br />и пункт 5 ст. 186 Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>24.</p>
							</td>
							<td>
								<p>
									Рассмотрение заявок юридических лиц и индивидуальных предпринимателей на приобретение подарочных
									сертификатов
									<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и индивидуальные предприниматели,
									<br />
									подавшие через сайт https://sertifikat.evroopt.by заявку на приобретение подарочных сертификатов
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты, адрес места
									государственной регистрации
									<br />
									(для индивидуального предпринимателя), банковские реквизиты (для индивидуального предпринимателя)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется
									<br />
									в процессе трудовой (служебной) деятельности субъекта персональных данных
									<br />
									в случаях, предусмотренных законодательством (для юридических лиц),
									<br />
									и на основании
									<br />
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для индивидуальных предпринимателей)
								</p>
								<p>
									(абзацы 8 и 15 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;, ст.ст. 7, 49
									<br />и пункт 5 ст. 186 Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>25.</p>
							</td>
							<td>
								<p>
									Рассмотрение коммерческих предложений поставщиков (юридических лиц и индивидуальных предпринимателей)
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и индивидуальные предприниматели,
									<br />
									подавшие через сайт <a href="https://evroopt.by/">https://evroopt.by</a> коммерческое предложение о
									поставке товаров
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Собственное имя,&nbsp; контактный номер телефона (необязательно), адрес электронной почты, иные
									данные, указанные в коммерческом предложении
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется
									<br />
									в процессе трудовой (служебной) деятельности субъекта персональных данных
									<br />
									в случаях, предусмотренных законодательством (для юридических лиц),
									<br />
									и на основании
									<br />
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для индивидуальных предпринимателей)
								</p>
								<p>
									(абзацы 8 и 15 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;, ст.ст. 7, 49
									<br />и пункт 5 ст. 186 Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
								</p>
								<p>10 лет после окончания срока действия договора</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>26.</p>
							</td>
							<td>
								<p>Предварительная запись на личный прием</p>
							</td>
							<td>
								<p>Лица, обратившиеся на личный прием</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, занимаемая
									<br />
									должность (для работников
									<br />
									ООО &laquo;Евроторг&raquo;), контактный номер телефона, суть вопроса
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;, ст. 6 Закона от 18.07.2011 № 300-З &laquo;Об
									обращениях граждан и юридических лиц&raquo;)
								</p>
							</td>
							<td>
								<p>1 год со дня предварительной записи на личный прием</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>27.</p>
							</td>
							<td>
								<p>Реализация прав потребителей в соответствии с законодательством о защите прав потребителей</p>
							</td>
							<td>
								<p>Покупатели (потребители, клиенты)</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства, контактный номер телефона,
									<br />
									адрес электронной почты (необязательно), номер бонусной карты (необязательно)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),&nbsp;
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021
									<br />
									№ 99-З &laquo;О защите персональных данных&raquo;, ст.ст. 2, 5, 15 и 16 Закона от 09.01.2002 № 90-З
									<br />
									&laquo;О защите прав потребителей&raquo;)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>28.</p>
							</td>
							<td>
								<p>
									Рассмотрение кандидатов (анкет (резюме) соискателей) на вакантные должности в целях заключения
									трудового договора
								</p>
							</td>
							<td>
								<p>
									Физические лица (соискатели на трудоустройство), направившие анкеты (резюме), в том числе
									заполнившие&nbsp; онлайн-анкету или заявку на вакансию на сайте https://e-rabota.by, либо прошедшие
									онлайн- собеседование в онлайн-рекрутере &laquo;Евроопт&raquo; посредством мессенджера Telegram
									(https://telegram.me/
									<br />
									recruit_euroopt_bot), либо откликнувшиеся на размещенные вакансии на сайте https://e-rabota.by или
									сайтах для поиска работы (например, rabota.by), либо заполнившие анкету посредством звонка в
									контактный центр по
									<br />
									тел. 7497 (единый номер для всех мобильных операторов) или тел. (017) 2798080, разместившие анкеты
									(резюме) на сайтах для поиска работы (например, rabota.by)
								</p>
							</td>
							<td>
								<p>
									Фотоизображение (необязательно), фамилия, собственное имя, отчество, дата рождения, гражданство, место
									рождения, семейное положение, адрес места жительства (регистрации), контактный номер телефона, адрес
									электронной почты, сведения
									<br />
									об образовании, трудовой деятельности, воинском учете, наличии несовершеннолетних детей, привлечении к
									административной и уголовной ответственности и иные данные, указанные в анкете (резюме) соискателя
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;) &ndash; при заполнении в<br />
									ООО &laquo;Евроторг&raquo; анкеты (резюме) в письменной форме, при направлении (заполнении) анкеты
									(резюме), онлайн-анкеты или заявки на вакансию в электронном виде (на адрес электронной почты
									HR@eurotorg.by, через сайт https://e-rabota.by, онлайн-собеседование
									<br />
									в онлайн-рекрутере &laquo;Евроопт&raquo; (посредством мессенджера Telegram), посредством звонка
									<br />в контактный центр Оператора)
								</p>
								<p>&nbsp;</p>
								<p>
									Абзац 16 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo; (обработка персональных данных, указанных в документе,
									адресованном оператору и подписанном субъектом персональных данных, в соответствии с содержанием
									такого документа) &ndash; при направлении (предоставлении) анкеты (резюме) в письменной форме или по
									электронной почте в виде электронного документа, подписанного электронной цифровой подписью
								</p>
								<p>&nbsp;</p>
								<p>
									Абзац 19 ст. 6 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo; (обработка распространенных ранее персональных
									данных)&nbsp; &ndash;при рассмотрении анкеты (резюме), размещенной на сайтах для поиска работы
									(например, rabota.by)
								</p>
							</td>
							<td>
								<p>1 год со дня дачи согласия, либо заполнения (поступления) анкеты (резюме)</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>29.</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Ведение внешнего кадрового резерва кандидатов (соискателей) на вакансии в ООО &laquo;Евроторг&raquo;
									(для поиска и отбора кандидатов на замещение вакансий), информирование кандидатов о подходящих
									вакансиях
								</p>
							</td>
							<td>
								<p>
									Соискатели на трудоустройство, которым отказано в дальнейшем рассмотрении по текущей вакансии
									(вакансии, на которую откликнулся кандидат), либо которые сами отказались от рассмотрения предложенной
									им вакансии
								</p>
							</td>
							<td>
								<p>
									Фотоизображение (необязательно), фамилия, собственное имя, отчество, дата рождения, гражданство, место
									рождения, семейное положение, адрес места жительства (регистрации), контактный номер телефона, адрес
									электронной почты, сведения
									<br />
									об образовании, трудовой деятельности, воинском учете, наличии несовершеннолетних детей, привлечении к
									административной и уголовной ответственности и иные данные, указанные в анкете (резюме) соискателя
								</p>
							</td>
							<td>
								<p>
									Согласие (ст. 5 Закона от 07.05.2021 № 99-З
									<br />
									&laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>3 года со дня дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>30.</p>
							</td>
							<td>
								<p>Оформление (прием) на работу</p>
							</td>
							<td>
								<p>Соискатели на трудоустройство, члены их семей</p>
							</td>
							<td>
								<p>
									В соответствии со ст. 26 Трудового кодекса Республики Беларусь (далее &ndash; ТК) и иными
									законодательными актами
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется при оформлении трудовых (служебных) отношений, а также в
									процессе трудовой (служебной)&nbsp; деятельности субъекта персональных данных
									<br />в случаях, предусмотренных законодательством (абзац 8 ст. 6, абзац 3 п. 2 ст. 8 Закона от
									07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;, ст. 26 ТК, п. 11 Декрета Президента
									Республики Беларусь от 15.12.2014 № 5 &laquo;Об усилении требований к руководящим кадрам и работникам
									организаций&raquo;)
								</p>
							</td>
							<td>
								<p>55 лет после увольнения</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>31.</p>
							</td>
							<td>
								<p>
									Рассмотрение обращений граждан и юридических лиц, в том числе внесенных в книгу замечаний и
									предложений, анализ таких обращений
								</p>
							</td>
							<td>
								<p>
									Физические лица, в том числе представители юридических лиц, направившие обращение, иные лица, чьи
									персональные данные указаны в обращении
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество (либо инициалы), адрес места жительства (места пребывания), суть
									обращения, личная подпись, иные сведения, указанные в обращении
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),
									предусмотренных законодательными актами (абзац 20 ст. 6, абзац 17 пункта 2 ст. 8 &nbsp;Закона от
									07.05.2021
									<br />
									№ 99-З &laquo;О защите персональных данных&raquo;, ст. 12 Закона от 18.07.2011 № 300-З
									<br />
									&laquo;Об обращениях граждан и юридических лиц&raquo;)
								</p>
							</td>
							<td>
								<p>5 лет с даты последнего обращения,</p>
								<p>5 лет после окончания ведения книги замечаний и предложений и передачи ее в архив организации</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>32.</p>
							</td>
							<td>
								<p>Рассмотрение заявлений субъектов персональных данных</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Физические лица &ndash; субъекты персональных данных, направившие заявление для реализации прав,
									предусмотренных статьями 10-13 Закона от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;, иные лица, чьи персональные данные указаны в
									заявлении
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства (места пребывания), дата рождения,
									идентификационный номер, номер документа, удостоверяющего личность субъекта персональных данных, суть
									заявления, личная подпись либо электронная цифровая подпись, иные сведения, указанные в заявлении
								</p>
							</td>
							<td>
								<p>Обработка персональных данных без согласия субъекта персональных данных прямо предусматривается</p>
								<p>
									Законом от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo; и иными законодательными актами (абзац 21 ст.
									6, абзац 18 пункта 2 ст. 8 и ст. 14 названного Закона)
								</p>
							</td>
							<td>
								<p>1 год с даты поступления заявления</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>33.</p>
							</td>
							<td>
								<p>
									Учет согласий субъектов персональных данных на обработку их персональных данных после окончания срока,
									на который дается согласие
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Физические лица &ndash; субъекты персональных данных, давшие согласие на обработку их персональных
									данных
								</p>
							</td>
							<td>
								<p>Персональные данные, указанные в согласии субъектов персональных данных на их обработку</p>
							</td>
							<td>
								<p>Обработка персональных данных без согласия субъекта персональных данных прямо предусматривается</p>
								<p>
									законодательными актами (абзац 21 ст. 6, абзац 18 пункта 2 ст. 8&nbsp; Закона от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;,
								</p>
								<p>
									Закон от 25.11.2011
									<br />
									№ 323-З &laquo;Об архивном деле и делопроизводстве&raquo;, постановление Министерства юстиции
									Республики Беларусь
									<br />
									от 24.05.2012 № 140
									<br />
									&laquo;О перечне типовых документов&raquo;)
								</p>
							</td>
							<td>
								<p>1 год после окончания срока, на который дается согласие</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>34.</p>
							</td>
							<td>
								<p>
									Заключение и исполнение гражданско-правовых договоров с субъектами хозяйствования (контрагентами),
									учет и анализ договоров, контроль за исполнением договорных обязательств
								</p>
							</td>
							<td>
								<p>
									Работники Оператора
									<br />и контрагента, уполномоченные на подписание договоров, иные лица, чьи персональные данные
									указаны в договорах
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество работника, иного лица, должность работника, иного лица,
									подписавшего договор, иные данные в соответствии с условиями договора (при необходимости)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (абзац 8 ст. 6 Закона от 07.05.2021 №
									99-З &laquo;О защите персональных данных&raquo;, ст.ст. 7, 49 и пункт 5 ст. 186 Гражданского кодекса
									Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами проверка соблюдения налогового законодательства не проводилась &ndash; 10 лет
									после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>35.</p>
							</td>
							<td>
								<p>Предоставление безвозмездной (спонсорской) помощи физическим лицам&nbsp;</p>
							</td>
							<td>
								<p>
									Лица, которым оказывается безвозмездная (спонсорская) помощь, иные лица, чьи персональные данные
									указаны в договоре
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, адрес места жительства, паспортные данные, контактный номер
									телефона лица, которому оказывается безвозмездная (спонсорская) помощь, иного лица, подписавшего
									договор
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных на основании договора, заключенного (заключаемого) с субъектом
									персональных данных, в целях совершения действий, установленных этим договором (абзац 15 ст. 6 Закона
									от 07.05.2021
									<br />№ 99-З &laquo;О защите персональных данных&raquo;)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash;
									<br />
									10 лет после окончания срока действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>36.</p>
							</td>
							<td>
								<p>
									Определение круга
									<br />
									и ведение учета аффилированных лиц
									<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Аффилированные лица
									<br />
									ООО &laquo;Евроторг&raquo;
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, сведения о степени родства и занимаемой должности, основания, в
									силу которых лицо признается аффилированным
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),&nbsp;
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021
									<br />
									№ 99-З &laquo;О защите персональных данных&raquo;, ст.ст. 56 и 63 Закона от 09.12.1992 № 2020-XII
									<br />
									&laquo;О хозяйственных обществах&raquo;)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами
									<br />
									проверка соблюдения налогового законодательства не проводилась &ndash; 10 лет после окончания срока
									действия договора
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>37.</p>
							</td>
							<td>
								<p>
									Повышение контроля качества обслуживания клиентов контактного центра (в том числе разрешение возможных
									конфликтных ситуаций, управление качеством консультаций)
								</p>
							</td>
							<td>
								<p>
									Покупатели, участники программ лояльности &laquo;Еплюс&raquo;, участники и победители рекламных игр
									(&laquo;Удача в придачу!&raquo; и др.), соискатели на трудоустройство, иные физические лица,
									обратившиеся на телефоны контактного центра (горячей линии)
								</p>
							</td>
							<td>
								<p>
									Аудиозапись телефонного разговора, номер телефона абонента, с которого осуществлен звонок, иные
									персональные данные, указанные в ходе телефонного разговора
								</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>6 месяцев со дня дачи согласия</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>38.</p>
							</td>
							<td>
								<p>Обеспечение пропускного режима на объектах ООО &laquo;Евроторг&raquo;, находящихся под охраной</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>Посетители указанных объектов</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя (на русском языке и в латинской транскрипции, указанной в паспорте),
									отчество&nbsp;
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),&nbsp;
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021 № 99-З &laquo;О защите
									персональных данных&raquo;, Закон от 08.11.2006 № 175-З &laquo;Об охранной деятельности&raquo;, Указ
									Президента Республики Беларусь от 25.10.2007 № 534 &laquo;О мерах по совершенствованию охранной
									деятельности&raquo;)
								</p>
							</td>
							<td>
								<p>1 год со дня посещения объекта</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>39.</p>
							</td>
							<td>
								<p>
									Обеспечение пропускного режима для транспортных средств на объектах ООО &laquo;Евроторг&raquo;,
									находящихся под охраной
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>Водители транспортных средств, въезжающие на охраняемую территорию указанных объектов</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, регистрационный знак транспортного
									средства (для физического лица, в том числе индивидуального предпринимателя, &ndash; собственника
									транспортного средства)
								</p>
							</td>
							<td>
								<p>
									Обработка персональных данных является необходимой для выполнения обязанностей (полномочий),&nbsp;
									предусмотренных законодательными актами (абзац 20 ст. 6 Закона от 07.05.2021 № 99-З &laquo;О защите
									персональных данных&raquo;, Закон от 08.11.2006 № 175-З &laquo;Об охранной деятельности&raquo;, Указ
									Президента Республики Беларусь от 25.10.2007 № 534
									<br />
									&laquo;О мерах по совершенствованию охранной деятельности&raquo;)
								</p>
							</td>
							<td>
								<p>1 год со дня посещения объекта</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>40.</p>
							</td>
							<td>
								<p>
									Создание (регистрация) и использование личного кабинета на сайте{' '}
									<a href="https://ir.eurotorg.by/ru/">https://ir.eurotorg.by/ru</a> в разделе &laquo;Центр
									результатов&raquo; для получения доступа к размещенным в нем финансовым и операционным показателям
									работы ООО &laquo;Евроторг&raquo; и иной информации о компании (презентациям, пресс-релизам,
									финансовой отчетности)
								</p>
							</td>
							<td>
								<p>
									Физические лица &ndash; пользователи сайта{' '}
									<a href="https://ir.eurotorg.by/ru/">https://ir.eurotorg.by/ru</a>, в том числе индивидуальные
									предприниматели (инвесторы, аналитики, иные), зарегистрировавшие личный кабинет
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Собственное имя, фамилия (необязательно), страна местонахождения, адрес электронной почты, роль
									(инвестор, аналитик, другое), IP-адрес устройства, метаданные браузера, UUID (универсальный уникальный
									идентификатор)
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>Согласие (ст. 5 Закона от 07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;)</p>
							</td>
							<td>
								<p>
									3 года с даты последней авторизации (входа) в личном кабинете, а в случае неиспользования личного
									кабинета &ndash; 3 года с даты дачи согласия
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>41.</p>
							</td>
							<td>
								<p>
									Размещение ООО &laquo;Евроторг&raquo; заказов поставщикам на сайте https://contractor-evroopt.by& (о
									поставке товаров в торговые объекты)
								</p>
							</td>
							<td>
								<p>
									Представители юридических лиц и индивидуальные предприниматели (поставщики товаров),
									зарегистрированные на сайте https://contractor-evroopt.by
								</p>
							</td>
							<td>
								<p>
									Фамилия, собственное имя, отчество, контактный номер телефона, адрес электронной почты, адрес места
									государственной регистрации (для индивидуального предпринимателя), банковские реквизиты (для
									индивидуального предпринимателя)
								</p>
								<p>&nbsp;</p>
							</td>
							<td>
								<p>
									Обработка персональных данных осуществляется в процессе трудовой (служебной) деятельности субъекта
									персональных данных в случаях, предусмотренных законодательством (для юридических лиц), и на основании
									договора, заключенного (заключаемого) с субъектом персональных данных, в целях совершения действий,
									установленных этим договором (для индивидуальных предпринимателей) (абзацы 8 и 15 ст. 6 Закона от
									07.05.2021 № 99-З &laquo;О защите персональных данных&raquo;, ст.ст. 7, 49 и пункт 5 ст. 186
									Гражданского кодекса Республики Беларусь)
								</p>
							</td>
							<td>
								<p>3 года после проведения налоговыми органами проверки соблюдения налогового законодательства.</p>
								<p>
									Если налоговыми органами проверка соблюдения налогового законодательства не проводилась &ndash; 10 лет
									после окончания срока действия договора
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
