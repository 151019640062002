import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from '../userMenu.module.scss';
import { ReactComponent as PersonalSVG } from 'assets/images/sidebar/personal.svg';

interface IPersonalProps {
	link: string;
	title: string;
	onClick?: () => void;
	mix?: object;
}

export const Personal = ({ link, title }: IPersonalProps) => {
	const LinkBody = () => (
		<Fragment>
			<PersonalSVG />

			<span className={styles.list__item_text}>{title}</span>
		</Fragment>
	);

	return (
		<a href={link} className={classNames(styles.list__item, styles.list__person)}>
			<LinkBody />
		</a>
	);
};
