import React, { FC } from 'react';
import styles from './api.module.scss';
import { ReactComponent as APISVG } from 'assets/images/sidebar/api.svg';

interface IAPILink {
	text: string;
}
export const APILink: FC<IAPILink> = ({ text }) => {
	return (
		<a className={styles.apiLink} href="/API.docx" download>
			<APISVG />

			<span className={styles.apiLink__text}>{text}</span>
		</a>
	);
};
