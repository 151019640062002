import React from 'react';
import { IStoreState } from 'services/store/interfaces';
import { FilterList } from 'components/Filter/FilterList';
import { SettingList } from 'components/Settings/SettingList';
import { Message } from 'components/Common/Message';
import { Mobile } from 'components/HOCS/Mobile';
import { useSelector } from 'react-redux';

export const MobileGroup = () => {
	const isFilter = useSelector((state: IStoreState) => state.filter.isShowOrders);
	const message = useSelector(
		(state: IStoreState) => state.orders.message,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);
	const isSettings = useSelector((state: IStoreState) => state.setting.isShowOrders);
	const isActions = isFilter || isSettings;
	const orders = useSelector(
		(state: IStoreState) => state.orders.ordersList,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);
	const isShowMessage = !isActions && orders.length === 0;

	return (
		<Mobile>
			{isFilter && <FilterList type="orders" />}
			{isSettings && <SettingList type="orders" />}
			{isShowMessage && <Message text={message} />}
		</Mobile>
	);
};
