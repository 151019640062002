import React, { FC, useState, useEffect } from 'react';
import styles from './pagination.module.scss';
import classNames from 'classnames';
import { Arrow } from 'assets/images/pagination/Arrow';
import { Page } from '../Pagination';
import OutsideClickHandler from 'react-outside-click-handler';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as SearchSVG } from 'assets/images/pagination/search.svg';
import { useTranslation } from 'react-i18next';
import { useCountPagination } from 'services/hooks/useBreakpoint';

interface IList {
	pages: Page[];
	onClick: (id: string) => void;
	activePage: number | undefined;
}

interface ICenterPages {
	pages: Page[];
	onClick: (id: string) => void;
	activePage: number;
	isCenterPoint: boolean;
}

interface IPoint {
	page: Page;
	onClick: () => void;
	isActive: boolean;
	isCenter?: boolean;
}

interface IPages {
	pages: Page[];
	onClick: (id: string) => void;
	activePage: number;
	isCenter?: boolean;
	isBlock?: boolean;
}

const Point: FC<IPoint> = ({ page, onClick, isActive, isCenter = false }) => {
	return (
		<div
			className={classNames(styles.list__item, {
				[styles.list__item_center]: isCenter,
				[styles.list__item_active]: isActive,
			})}
			key={page.id}
			onClick={onClick}
		>
			{page.text}
		</div>
	);
};

export const Pages: FC<IPages> = ({ pages, activePage, onClick, isCenter = false, isBlock = false }) => {
	return (
		<div
			className={classNames(styles.list__block, { [styles.list__center]: isCenter, [styles.list__dpropdown]: isBlock })}
		>
			{pages.map((page) => (
				<Point
					key={page.id}
					page={page}
					onClick={() => onClick(page.text)}
					isActive={(activePage as unknown as string) == page.text}
					isCenter={isBlock}
				/>
			))}
		</div>
	);
};
const CenterPages: FC<ICenterPages> = ({ pages, isCenterPoint, activePage, onClick }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(t('pagination.page'));
	const [isVisible, setIsVisible] = useState(false);
	const [isFocused, setIsFocus] = useState(false);
	const [filteredPages, setFilteredPages] = useState(pages);

	useEffect(() => {
		setFilteredPages(pages);
	}, [JSON.stringify(pages)]);

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				setIsVisible(false);
				setFilteredPages(pages);
				setValue(t('pagination.page'));
			}}
		>
			<div className={styles.list__center}>
				{isCenterPoint ? (
					<Pages pages={pages} onClick={onClick} activePage={activePage} isCenter />
				) : (
					<div className={styles.list__item} onClick={() => setIsVisible(!isVisible)}>
						...
					</div>
				)}

				<AnimatePresence>
					{isVisible && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.1 }}
							className={classNames(styles.list__hidden, {
								[styles.list__hidden_less5]: filteredPages.length < 5,
								[styles.list__hidden_less4]: filteredPages.length < 4,
								[styles.list__hidden_less3]: filteredPages.length < 3,
								[styles.list__hidden_empty]: filteredPages.length < 2,
							})}
						>
							<div className={styles.list__pages} style={{ minHeight: 32, maxHeight: 5 * 32 }}>
								<Pages
									pages={filteredPages}
									activePage={activePage}
									onClick={(value) => {
										setIsVisible(false);
										onClick(value);
										setValue(t('pagination.page'));
										setFilteredPages(pages);
									}}
									isBlock
								/>
							</div>

							<div
								className={classNames(styles.list__search, {
									[styles.list__search_empty]: filteredPages.length < 2,
									[styles.list__search_focus]: isFocused,
								})}
							>
								<div className={styles.list__search_icon}>
									<SearchSVG />
								</div>

								<input
									className={classNames(styles.list__hidden_search, {
										[styles.list__hidden_search_empty]: filteredPages.length < 2,
									})}
									type="text"
									value={value}
									onBlur={() => setIsFocus(false)}
									onFocus={() => setIsFocus(true)}
									onChange={(e) => {
										const num = e.target.value.replace(/[^\d]/g, '');
										const isMore = Number(num) > Number(pages[pages.length - 1].text);
										setValue(isMore ? value : num);

										if (isMore) return;
										setFilteredPages(pages.filter(({ text }) => text.includes(num)));
									}}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</OutsideClickHandler>
	);
};

export const PointPagination: FC<IList> = ({ pages, onClick, activePage = 1 }) => {
	const [point, setPoint] = useState(0);
	const count = useCountPagination();

	const [left, setLeft] = useState(pages.slice(point, count + point));
	const [right, setRight] = useState(pages.slice(pages.length - count - point, pages.length - point));
	const [center, setCenter] = useState(pages.slice(point + count, pages.length - count - point));

	const isCenterPoint = Number(left[left.length - 1].text) + 2 === Number(right[0].text);

	useEffect(() => {
		const start = point < 0 ? 0 : point;
		const end = point < 0 ? pages.length - count - Math.abs(point) : pages.length - count;
		const rightEnd = point > 0 ? 0 : Math.abs(point);

		setLeft(pages.slice(start, start + count));
		setRight(pages.slice(end, pages.length - rightEnd));
		setCenter(pages.slice(start + count, end));
	}, [point, count]);

	useEffect(() => {
		if (isCenterPoint) setCenter([{ text: String(Number(right[0].text) - 1), id: 'kkmmww' }]);
	}, [isCenterPoint, count]);

	return (
		<div className={styles.list}>
			<Arrow
				onClick={() => {
					if (isCenterPoint && point > 0) return;
					if (isCenterPoint && point === 0) return;

					if (isCenterPoint && point < 0) {
						setPoint(0);
						return;
					}

					setPoint(point + 1);
				}}
				isDisabled={isCenterPoint && point > 0}
			/>

			<Pages pages={left} activePage={activePage} onClick={onClick} />

			<CenterPages pages={center} activePage={activePage} onClick={onClick} isCenterPoint={isCenterPoint} />

			<Pages pages={right} activePage={activePage} onClick={onClick} />

			<Arrow
				onClick={() => {
					if (isCenterPoint && point == 0) return;
					if (isCenterPoint && point < 0) return;

					if (isCenterPoint && point > 0) {
						setPoint(0);
						return;
					}

					setPoint(point - 1);
				}}
				isDisabled={isCenterPoint && point < 0}
				isRight
			/>
		</div>
	);
};
