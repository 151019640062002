import React, { FC, useState, useEffect } from 'react';
import { Portal } from 'components/Modals';
import styles from './info.module.scss';
import { ReactComponent as CloseSVG } from 'assets/images/downTimes/close.svg';
import { TDownTimesDayData, TNumDay, TNumRequired, TUpdateDownTimesPayload } from 'services/store/interfaces/downTimes';
import OutsideClickHandler from 'react-outside-click-handler';
import { Card } from './Card';
import { useDispatch, useSelector } from 'react-redux';
import { closeInfoModal, fetchRemoveWithUpdate, setCurrentGroup, setIsOpenInfo } from 'services/store/downTimes';
import { IStoreState } from 'services/store/interfaces';
import classNames from 'classnames';
import { Message } from '../Message';
import { useLanguage } from '../../../../../services/contexts/languageContext';

type TDays = Array<TNumDay | TNumRequired | TDownTimesDayData>;
type TInfoProps = {
	onClose: () => void;
	Monthx?: number;
	Datex?: string | number;
	DayId?: string;
	DateStart?: string;
	DateEnd?: string;
	Reason?: string;
	Descript?: string;
	Info?: TDays;
};

export const InfoModal: FC<TInfoProps> = ({ onClose, ...props }) => {
	const language = useLanguage();
	const { message, year, month } = useSelector((state: IStoreState) => state.downTimes);
	const [list, setList] = useState<TDays | undefined>(props.Info);
	const [count, setCount] = useState(0);

	const dispatch = useDispatch();
	const onRemove = (Id: string) => {
		const data = { DayId: Id, Language: language === 'ru' ? 1 : 2 } as unknown as TUpdateDownTimesPayload;
		dispatch(fetchRemoveWithUpdate(data, String(year), String(month.id), list?.length === 1));
	};

	const onEdit = (dayInfo: TNumRequired | TNumDay | TDownTimesDayData) => {
		dispatch(setIsOpenInfo(false));

		setTimeout(() => dispatch(setCurrentGroup({ dayInfo, type: 'edit' })), 300);
	};

	useEffect(() => {
		if (!list || !message[0] || message[0].type !== 'success') return;
		const down = list.map((item) => (message[0].DayId === item.DayId ? { ...item, IsHidden: true } : { ...item }));

		setList(down);
		setCount(count + 1);
	}, [JSON.stringify(message)]);

	useEffect(() => {
		if (count === 2) setTimeout(() => dispatch(closeInfoModal()), 500);
	}, [count]);

	return (
		<Portal>
			<div className={styles.modal}>
				<OutsideClickHandler onOutsideClick={onClose}>
					<div className={styles.modal_body}>
						<div
							className={classNames(styles.modal_close, {
								[styles.modal_close__message]: message.length > 0,
							})}
							onClick={onClose}
						>
							<CloseSVG />
						</div>

						{message.length > 0 && (
							<Message
								message={message}
								isHidden={message.length === 0}
								isSuccess={message.length > 0 && message[0].type === 'success'}
								isError={message.length > 0 && message[0].type === 'error'}
							/>
						)}

						<div
							className={classNames(styles.separate, {
								[styles.separate_message]: message.length > 0,
							})}
						/>

						{list?.map(({ Reason, DateStart, DateEnd, Descript, DayId, IsHidden }) => (
							<Card
								key={DayId}
								Datex={props.Datex}
								Reason={Reason}
								DateStart={DateStart}
								DateEnd={DateEnd}
								Descript={Descript}
								onRemove={onRemove}
								onEdit={() =>
									onEdit({
										Reason: Reason || '',
										DateStart: DateStart || '',
										DateEnd: DateEnd || '',
										Descript: Descript || '',
										DayId: DayId || '',
										Monthx: 1,
										Datex: DateStart || '',
									})
								}
								DayId={DayId}
								isHidden={IsHidden}
							/>
						))}
					</div>
				</OutsideClickHandler>
			</div>
		</Portal>
	);
};
