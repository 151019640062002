import React from 'react';
import classNames from 'classnames';
import styles from './arrow.module.scss';

type TArrow = {
	isDisabled?: boolean;
	isActive?: boolean;
	isDefault?: boolean;
	isRight?: boolean;
	onClick: () => void;
};
export const Arrow = ({ isActive, isDefault, isRight, onClick, isDisabled }: TArrow) => {
	return (
		<div
			className={classNames(styles.arrow, {
				[styles.arrow__right]: isRight,
				[styles.arrow__disabled]: isDisabled,
			})}
			onClick={onClick}
		>
			<svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.83776 2.04161C6.13682 1.57729 6.00285 0.958464 5.53854 0.65941C5.37077 0.551352 5.18282 0.49983 4.99708 0.5C4.66876 0.500296 4.34732 0.662091 4.15633 0.958637L0.667321 6.37568C0.665633 6.3783 0.663949 6.38092 0.66227 6.38355L0.318578 6.91716C-0.106193 7.57667 -0.106193 8.4236 0.31858 9.0831L0.662295 9.61675C0.663967 9.61937 0.665643 9.62198 0.667324 9.62459L4.15633 15.0416C4.26847 15.2157 4.4256 15.3434 4.60267 15.4192C4.85237 15.5261 5.14174 15.5298 5.40067 15.4152C5.44778 15.3944 5.49387 15.3696 5.53854 15.3408C6.00285 15.0418 6.13682 14.4229 5.83776 13.9586L2.34874 8.54162C2.34777 8.5401 2.34679 8.53858 2.34583 8.53706C2.13733 8.20949 2.13733 7.79076 2.34583 7.46319L5.83776 2.04161Z"
					fill={isDefault ? '#7B8290' : isActive ? '#212122' : '#d0d1de'}
				/>
			</svg>
		</div>
	);
};
